import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import { PaymentNavBarComponent } from '../../components/PaymentNavBar';
import $ from 'jquery';
import { store } from "../../redux/store";
import { loaderState, idTokens } from "../../redux/actions/Action";
import {  Redirect } from 'react-router-dom';
import html2canvas from "html2canvas";
import { logImage } from "../../utility/utility.js";
import SRCWrapper from "../../utility/SRCWrapper";
import "../../scss/_inputBox.css";
import DataParamsController from "../../Controllers/DataParamsController";


export class CompleteIdentity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationData: "",
      errorStatus: false,
      errorMessage: null,
      errorDescription: null,
      completeValidation: false,
      getProfiles: false,
      otpError: false,
      response: true
    };
    this.identityCompleteValidation = this.identityCompleteValidation.bind(
      this
    );
    this.getValidationData = this.getValidationData.bind(this);
  }
  getValidationData = e => {
    this.setState({
      validationData: e.target.value
    });
  };
  identityCompleteValidation = async () => {
      this.setState({
        validationData: $("#otpInput").text()
      });
  
      store.dispatch(loaderState(true));

      try {
        var dataURL;

        html2canvas(document.querySelector('#otpConfirmation'), {
          letterRendering: 1, allowTaint: true,
          width: 1000,
          height: 600
        }).then(function (canvas) {
          dataURL = canvas.toDataURL();
          dataURL = dataURL.replace(/^data:image\/\w+;base64,/, "");
        });
        
        var completeValidationConst = await DataParamsController.getParamsForTheAPICall("completeIdentityValidation");


        completeValidationConst.validationData = $("#otpInput").text();
        console.log(completeValidationConst.validationData);
        let response = await window.DGNCheckout.completeIdentityValidation(
          completeValidationConst
        );

        if (response.idToken) {
          logImage(SRCWrapper.srcTransactionID, "identityLookupCompleted", dataURL);

          store.dispatch(loaderState(false));
          store.dispatch(idTokens(response.idToken));

          this.setState({
            getProfiles: true
          });
        } else {
          store.dispatch(loaderState(false));
          this.setState({
            errorStatus: true,
            errorMessage: response.error.reason,
            errorDescription: response.error.details[0].message
          });
        }
      } catch (error) {
        store.dispatch(loaderState(false));
        this.setState({
          errorStatus: true,
          errorMessage: error.reason,
          errorDescription: error.description
        });

      }
    
  };
  validateFunction() {

  }

  render() {
    return (
      <div id="otpConfirmation">
        {this.state.getProfiles ? <Redirect to='/scenario_4/profiles' /> : null}


        <Container fluid={true} className="checkout-page-container">
          <PaymentNavBarComponent />
          <div className="row ">
            <p className="col-12 mt-2 text-center payment-confirmation-title">Enter the code sent to selected mode</p>

          </div>

          <form className="row mb-1" id="frmEnterOTP">
            <div className="form-group w-100 billing-address-form  px-5">

              {/* div input for screen-shot caputring with added data */}
              <div id="otpInput" className="single-line inputBox otpBox form-group billing-address-form form-control" 
                  contenteditable="true"
                  data-max-length="10"
                  placeholder="- - - - - -"
                  onChange={
                          this.getValidationData
                        }
                    >
              </div>

              {this.state.errorStatus ? (
                <small className='validation-msg' >{this.state.errorDescription}</small>
              ) : null}

              {this.state.otpError ? (
                <small className='validation-msg' >Enter OTP number.</small>
              ) : null}

            </div>
          </form>

          <div className="row btn-continue-row-otp">
            <div className="col-md-12 col-sm-12 align-self-center">
              <div className="checkout-button text-center px-4">

                <button type="button" onClick={this.identityCompleteValidation} id="btnCompleteIdentity"
                  className="btn btn-continue btn-lg btn-block mt-4">CONTINUE</button>

              </div>

            </div>

          </div>
          <FooterNavBarComponent />
        </Container>
      </div>
    );
  }
}
export default CompleteIdentity;