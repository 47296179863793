import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import { PaymentNavBarComponent } from '../../components/PaymentNavBar';
import { store } from "../../redux/store";
import { loaderState } from "../../redux/actions/Action";
import { identityLookup, identityInitiate } from "../../utility/dataConstansts";
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import html2canvas from "html2canvas";
import { logImage } from "../../utility/utility.js";
import SRCWrapper from "../../utility/SRCWrapper";
import "../../scss/_inputBox.css";
import DataParamsController from "../../Controllers/DataParamsController";

export class ScenarioInitiateIdentity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identityValue: "testfeb8@gmail.com",
      errorStatus: false,
      errorMessage: null,
      errorDescription: null,
      completeValidation: false
    };
    this.identityLookup = this.identityLookup.bind(this);
    this.getIdentityValue = this.getIdentityValue.bind(this);
  }

  getIdentityValue = e => {
    this.setState({
      identityValue: e.target.value
    });
  };

  identityLookup = async () => {

    var emailInput = $("#emailAddressInput").text();
    emailInput = emailInput.trim();

    this.setState({
      identityValue: emailInput
    });
    console.log(" state " + this.state.identityValue);

    try {
      var dataURL;
      html2canvas(document.querySelector('#initiateIdentityLookup'), {
        width: 1000,
        height: 600
      })
        .then(function (canvas) {
          dataURL = canvas.toDataURL();
          dataURL = dataURL.replace(/^data:image\/\w+;base64,/, "");
        });


      if (this.state.identityValue === null) {
        this.setState({
          errorStatus: true,
          errorMessage: "Please enter valid value"
        });
      } else {

        var identityLookupParams = await DataParamsController.getParamsForTheAPICall("identityLookup");

        identityLookupParams.consumerIdentity.identityValue = emailInput;
        console.log("identity value " + identityLookupParams.consumerIdentity.identityValue);

        store.dispatch(loaderState(true));

        let identityLookupResponse = await window.DGNCheckout.identityLookup(
          identityLookupParams
        );

        if (identityLookupResponse.consumerPresent === true) {
          var identityInitiateParams = await DataParamsController.getParamsForTheAPICall("initiateIdentityValidation");


          let response = await window.DGNCheckout.initiateIdentityValidation();

          logImage(SRCWrapper.srcTransactionID, "identityLookup", dataURL);

          if (response.error) {
            store.dispatch(loaderState(false));
            this.setState({
              errorStatus: true,
              errorMessage: response.error[0].reasonCode,
              errorDescription: response.error[0].description
            });
          } else {
            console.log("successs");

            store.dispatch(loaderState(false));
            this.setState({
              completeValidation: true,
              validationMsg: response.validationMessage
            });
          }
        } else {
          console.log(identityLookupResponse);
          store.dispatch(loaderState(false));

          this.setState({
            errorStatus: true,
            errorMessage: "Your ID is not registered with us.",
            errorDescription: "Your ID is not registered with us."
          });

          if (identityLookupResponse.error[0].description != null) {
            this.setState({
              errorStatus: true,
              errorMessage: identityLookupResponse.error[0].reasonCode,
              errorDescription: identityLookupResponse.error[0].description
            });

          }
        }
      }
    } catch (error) {
      store.dispatch(loaderState(false));
      this.setState({
        errorStatus: true,
        errorDescription: error.description
      });
    }
  };

  componentDidMount() {
    store.dispatch(loaderState(false));
  }

  render() {
    return (
      <div id="initiateIdentityLookup">
        <Container fluid={true} className="checkout-page-container">
          {this.state.completeValidation === true ?
            <Redirect to='/complete-identity' /> : null}


          <PaymentNavBarComponent />
          <div className="row text-center card-details-container">
            <p className="col-6 mt-2 mb-2 card-details-title returningUser disabled">New User</p>
            <p className="col-6 mt-2 mb-2 card-details-title ">Returning User</p>
          </div>

          <div className="row ">
            <p className="col-12 mt-2 text-center payment-confirmation-title">Enter the email for consumer identity.</p>

          </div>

          <form className="row mb-1">
            <div className="form-group w-100 billing-address-form px-5">

              {/* div input for screen-shot caputring with added data  */}
              <div className="single-line inputBox form-group billing-address-form form-control"
                contenteditable="true" data-max-length="20"
                id="emailAddressInput"
                aria-describedby="emailHelp"
                placeholder="Email Address">
              </div>
            
              {
                this.state.errorStatus ? (<small className='validation-msg' > {
                  this.state.errorMessage
                } <
                    br /> {
                    this.state.errorDescription
                  } </small>
                ) : null
              }

            </div>

          </form>

          <div className="row btn-continue-row-billing-address pb-3">

            <div className="col-md-12 col-sm-12 align-self-center">
              <div className="checkout-button text-center px-4">

                <button type="button" onClick={this.identityLookup} id="btnConsumerIdentity"
                  className="btn btn-continue btn-lg btn-block mt-4">CONTINUE</button>
              </div>

            </div>

          </div>
          <FooterNavBarComponent />
        </Container>
      </div>
    );
  }
}

export default ScenarioInitiateIdentity;