import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { SearchNavBarComponent } from '../../components/SearchNavBar';
import SRCWrapper from "../../utility/SRCWrapper";
import { store } from "../../redux/store";
import { loaderState, idTokens, dpaID } from "../../redux/actions/Action";
import html2canvas from "html2canvas";
import { logImage } from "../../utility/utility.js";
import { loggerHost } from "../../logger/loggerConstants";
import DataParamsController from "../../Controllers/DataParamsController";

var urlDataParam = false;

export class SF_CheckoutPage extends Component {
  constructor(props) {
    super(props);

    this.processQueryParams();

    this.state = {
      responseStatus: false,
      responseMessage: "",
      errorStatus: false,
      errorMessage: "",
      dataConfiguration: []

    };
  }

  // Getting query params from URL
  processQueryParams() {
    var url = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    let jwtToken = "";
    let type = "manual";

    for (var i = 0; i < url.length; i++) {
      var urlparam = url[i].split('=');
      if (urlparam[0] == "data") {
        jwtToken = urlparam[1];
        urlDataParam = true;
      }
      if (urlparam[0] == "type") {
        type = urlparam[1];
      }
    }

    SRCWrapper.decodeJWTToken(jwtToken, type);
  };

  handleCheckoutClick = async () => {
    // Taking screen-shot

    try {
      var dataURL;
      html2canvas(document.querySelector('#checkoutTrigger'), {
        width: 1000,
        height: 700
      })
        .then(function (canvas) {
          dataURL = canvas.toDataURL();
          dataURL = dataURL.replace(/^data:image\/\w+;base64,/, "");
        });
    } catch (error) {
      console.log(error);
    }

    var initializationParams = await DataParamsController.getParamsForTheAPICall("init");

    initializationParams.srciTransactionId = SRCWrapper.srcTransactionID;
    store.dispatch(loaderState(true));

    try {
      // init call
      let initResponse = await window.DGNCheckout.init(
        initializationParams
      );


      if (initResponse.status == "200") {
        let recongizeResponse = await window.DGNCheckout.isRecognized();

        if (recongizeResponse.recognized == true) {
          logImage(SRCWrapper.srcTransactionID, "init", dataURL);
          store.dispatch(loaderState(false));
          store.dispatch(idTokens(recongizeResponse.idTokens));
          this.setState({ responseStatus: true });

          // Need to implement if recongized is true.
        } else {
          this.setState({ responseStatus: false });
          store.dispatch(loaderState(false));
          this.setState({
            errorStatus: true,
            errorMessage: "UnKnown device",
          });
        }
      } else {
        store.dispatch(loaderState(true));
        this.setState({
          errorStatus: true,
          errorMessage: initResponse.description,
        });
      }

    } catch (error) {
      store.dispatch(loaderState(true));
      this.setState({
        errorStatus: true,
        errorMessage: error.description,
      });
    }
  };

  inputFocusout = async (event) => {
    var inputValue = event.target.textContent;
    var inputID = event.target.id;
    var inputGroupName = event.target.closest("td").getAttribute('groupname');
    var counter = event.target.closest("td").getAttribute('counter');

    console.log(inputValue + " " + inputID + " " + inputGroupName + " " + counter);

    DataParamsController.updateTestDataValueInStore(inputID, inputValue, inputGroupName, counter);
  };

  closePopUpClick = async (event) => {
    document.getElementById("myModal").style.display = "none";
    document.getElementById("myCloseModal").style.display = "none";
  };

  handlePopUpClick = async () => {
    var configuration = await DataParamsController.getParamsForTheConfiguration();

    console.log(configuration);
    if (configuration != null && configuration.length > 0) {
      this.setState({ dataConfiguration: configuration });
      document.getElementById("myModal").style.display = "block";
    } else {
      document.getElementById("myCloseModal").style.display = "block";
    }
  };

  handleToggleClick = async (event) => {
    event.target.classList.toggle("active");
    var content = event.target.nextElementSibling;

    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  }


  componentDidMount() {
    store.dispatch(loaderState(false));

    if (urlDataParam) {
      document.getElementById("btnConfiguration").style.display = "block";
    } else {
      document.getElementById("btnConfiguration").style.display = "none";
    }
  }

  render() {
    return (
      <div id="checkoutTrigger">
        {this.state.responseStatus ? <Redirect to='/scenario_5/profiles' /> : null}
        <Container fluid={true} className="checkout-page-container checkout-page-border">
          <SearchNavBarComponent />

          <h2 className="text-center pt-3 checkout-page-yourcart-title">YOUR CART</h2><hr className="hr-line-dot" />
          <div className="row mb-2">
            <div className="col-md-12 col-sm-12 align-self-center col-border-bottom-line-dot">
              <h4 className="mx-4 p-1 checkout-page-items">ITEMS:</h4>
              <div className="row mb-2">
                <div className="col-6">
                  <img src={loggerHost.referenceAppuri + "godfather.jpg"} width="150" className="checkout-page-cart-item" />
                </div>
                <div className="col-6">
                  <p className="checkout-page-cart-item-name">The Godfather by Mario Puzo</p>

                  <p className="checkout-page-cart-item-jewels">
                    <span className="property-name">Qty:</span> <span className="property-value">1</span></p>
                  <p className="checkout-page-cart-item-jewels">
                    <span className="property-name">In Stock</span> <span className="property-value"></span></p>

                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 align-self-center mt-1">
              <button type="button" id="btnConfiguration" className="btn btn-checkout"
                onClick={this.handlePopUpClick}>Configure</button>


              <div className="d-flex checkout-page-cart-item-jewels-price">
                <div className="mr-auto px-3 py-2 ">SUBTOTAL</div>
                <div className="px-3 py-2">$10.00</div>
              </div>

              <div className="d-flex checkout-page-cart-item-jewels-price">
                <div className="mr-auto px-3 py-2">ESTIMATED SHIPPING & <br />HANDLING</div>
                <div className="px-3 py-2">FREE</div>
              </div>

              <div className="d-flex checkout-page-cart-item-jewels-total-price">
                <div className="mr-auto px-3 py-1">TOTAL</div>
                <div className="px-3 py-1">$10.00</div>
              </div>

            </div>
          </div>

          <hr className="hr-line-dot" />
          <div className="row">
            <div className="col-md-12 col-sm-12 align-self-center">
              <div className="checkout-button text-center px-4">
                <button type="button" id="btnSRCCheckout" className="btn btn-checkout btn-lg btn-block mt-2"
                  onClick={this.handleCheckoutClick}>SRC CHECKOUT </button>

                {this.state.errorStatus ? (
                  <small className='validation-msg' >{this.state.errorMessage}</small>
                ) : null}

                <img src={loggerHost.referenceAppuri + "discover-logo.png"} className="checkout-page-logo mt-3 mb-1" />

                <div id="popupTrigger">
                  <div id="myModal" className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={this.closePopUpClick}>&times;</span>
                      <h4 >Test Data Configuration</h4>

                      {
                        this.state.dataConfiguration.map((element, i) => {
                          return (
                            <div key={i}>
                              <button type="button" onClick={this.handleToggleClick} className="marginTop14px collapsible">{element.GroupName}</button>

                              <div className="content">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Param Name</th>
                                      <th className="w-100">Default Value</th>
                                    </tr>
                                  </thead>
                                  {
                                    element.Fields.map((field, counter) => {
                                      return (
                                        <tr key={counter}>
                                          <td>{field.ParameterName}</td>
                                          <td className="w-100" groupname={element.GroupName} counter={i}>
                                            <div className="w-100 card-details-form single-line testDataBox form-control py-4 testDataInput" contentEditable="true" data-max-length="10"
                                              name={field.ParameterName} id={field.ParameterName} aria-describedby="cardExpiryrHelp"
                                              onBlur={this.inputFocusout}  > {field.DefaultData} </div>

                                            {/* <input type="text" onBlur={this.inputFocusout} className="testDataInput" id={field.ParameterName} name={field.ParameterName} /> */}
                                          </td>

                                        </tr>
                                      )
                                    })
                                  }

                                </table>
                              </div>

                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>

                <div id="popupTrigger">
                  <div id="myCloseModal" className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={this.closePopUpClick}>&times;</span>
                      <h4 >No data available for configuration</h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Container>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  alert();
  return {
    loader: state.reducer.loaderState
  };
};

//export default connect(mapStateToProps)(CheckoutPage);