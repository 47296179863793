import EnvironWrapper from "./EnvironWrapper";
import IRAController from "../Controllers/IRAController";
import DataParamsController from "../Controllers/DataParamsController";
import { store } from "../redux/store";
import { idTokens, cardList } from "../redux/actions/Action";
import CardEncryption from "./CardEncryption";
import SRCWrapper from "./SRCWrapper";

class SdkMethodWrapper {
    // TODO : Abstract Factory
    // web ---> sdkMethodW
    // andriod---> 

    // ios -- > ios plugin
    constructor() {
        this.srciError = {
            reasonCode: "SRCI Error",
            description: "Error in network, Please try after sometime"
        }

        this.logType = "SRCI to SDK";
    }

    set isRecognizedEnv(env) {
        this._isRecognizedEnv = env;
    }

    get isRecognizedEnv() {
        return this._isRecognizedEnv;
    }

    set emailValidatedEnv(env) {
        this._emailValidatedEnv = env;
    }

    get emailValidatedEnv() {
        return this._emailValidatedEnv;
    }

    getSelectedEnvironments = async () => {
        console.log(EnvironWrapper.selectedEnvs);
        return EnvironWrapper.selectedEnvs;
    }

    getSDKFunctionParams = async () => { }


    init = async () => {
        this.emailValidatedEnv = null;
        this.isRecognizedEnv = null;

        let selectedEnvironments = await this.getSelectedEnvironments();

        let initErrorCounter = 0;
        var errorResponse = [];
        let params = {};


        for (const env of selectedEnvironments) {
            var initCaughtError = "";

            try {
                console.log(env);
                params = await DataParamsController.getParamsForTheAPICall("init", env);
                console.log(params);

                await EnvironWrapper.getSDKObject(env).init(params);
            }
            catch (error) {
                initCaughtError = error;
                errorResponse.push(error);
                console.log(error);
                initErrorCounter++;
            }
            finally {
                var response = "";
                if (initCaughtError != "") {
                    response = initCaughtError;
                }
                IRAController.storeCurrentTransactionLogs(params, response, "init", this.logType, env);
            }
        }

        console.log("init Error count " + initErrorCounter);
        return selectedEnvironments.length != initErrorCounter ? true : errorResponse[errorResponse.length - 1];
    }


    isRecognized = async () => {
        let selectedEnvironments = await this.getSelectedEnvironments();

        var errorResponses = [];
        var recongizeResponse = "";

        for (const env of selectedEnvironments) {
            var isRecognizedCaughtError = "";

            try {
                recongizeResponse = await EnvironWrapper.getSDKObject(env).isRecognized();
            }
            catch (error) {
                isRecognizedCaughtError = error;
                errorResponses.push(error);
                console.log(error);
            }
            finally {
                if (isRecognizedCaughtError != "") {
                    recongizeResponse = isRecognizedCaughtError;
                }

                IRAController.storeCurrentTransactionLogs({}, recongizeResponse, "isRecognized", this.logType, env);

                if (recongizeResponse.recognized == true) {
                    store.dispatch(idTokens(recongizeResponse.idTokens));
                    this.isRecognizedEnv = env;
                    return true;
                }

            }
        }

        console.log("is recognized Error count " + errorResponses.length);
        return selectedEnvironments.length != errorResponses.length ? true : errorResponses[errorResponses.length - 1];
    }


    identityLookup = async (email, isPhoneNoAdded = false) => {
        let selectedEnvironments = await this.getSelectedEnvironments();

        if (selectedEnvironments == null || selectedEnvironments.length <= 0) {
            return this.srciError;
        }

        let params = {};
        var lookupResponse = "";
        var validationErrorResponse = [], errorResponse = [];
        var isConsumerPresent = true;

        for (const env of selectedEnvironments) {
            var caughtError = "";

            try {
                params = await DataParamsController.getParamsForTheAPICall("identityLookup", env);
                params.consumerIdentity.identityValue = (params.consumerIdentity.identityValue == "") ?
                    email : params.consumerIdentity.identityValue;

                // TODO: Take this from constants
                params.consumerIdentity.identityType = (isPhoneNoAdded) ?
                    "MOBILE_PHONE_NUMBER" : params.consumerIdentity.identityType;

                console.log(params);

                lookupResponse = await EnvironWrapper.getSDKObject(env).identityLookup(params);
            }
            catch (error) {
                caughtError = error;
                errorResponse.push(error);
            }
            finally {
                if (caughtError != "") {
                    lookupResponse = caughtError;
                }

                IRAController.storeCurrentTransactionLogs(params, lookupResponse, "identityLookup", this.logType, env);

                if (lookupResponse.consumerPresent != null && lookupResponse.consumerPresent == true) {
                    var idetityValidation = await this.identityValidation(env);
                    console.log(idetityValidation);

                    if (idetityValidation != null && idetityValidation.validationMessage != null) {
                        this.emailValidatedEnv = env;
                        return true;
                    } else {
                        validationErrorResponse.push(idetityValidation);
                    }
                } else {
                    isConsumerPresent = false;
                }
            }
        }

        if (selectedEnvironments.length == errorResponse.length || selectedEnvironments.length == validationErrorResponse.length) {
            console.log(validationErrorResponse[validationErrorResponse.length - 1]);

            console.log(typeof (validationErrorResponse[validationErrorResponse.length - 1]));


            if (selectedEnvironments.length == validationErrorResponse.length) {
                return validationErrorResponse[validationErrorResponse.length - 1];
            }

            console.log(errorResponse[errorResponse.length - 1]);

            console.log(typeof (errorResponse[errorResponse.length - 1]));

            return errorResponse[errorResponse.length - 1];
        }

        return isConsumerPresent;
    }


    identityValidation = async (env) => {
        var response = "";

        var params = await DataParamsController.getParamsForTheAPICall("initiateIdentityValidation", env);

        console.log(params);
        try {
            response = await EnvironWrapper.getSDKObject(env).initiateIdentityValidation(params);
        } catch (error) {
            console.log(error);
            response = error;
        } finally {
            await IRAController.storeCurrentTransactionLogs(params, response, "initiateIdentityValidation", this.logType, env);
        }

        console.log(response);

        return response;
    }

    completeIdentityValidation = async (otp) => {
        var response = "";
        console.log(this.emailValidatedEnv);

        if (this.emailValidatedEnv != null) {
            let params = await DataParamsController.getParamsForTheAPICall("completeIdentityValidation", this.emailValidatedEnv);

            console.log(params);

            params.validationData = (params.validationData == "") ? otp : params.validationData;


            response = await this.completeIdentityExecution(this.emailValidatedEnv, params);
            console.log(response);

            if (response.idToken != null) {
                store.dispatch(idTokens(response.idToken));
                return response;
            }
            else {
                return response;
            }
        } else {
            return this.srciError;
        }
    }


    completeIdentityExecution = async (env, params) => {
        var caughtError = "";
        try {
            var response = await EnvironWrapper.getSDKObject(env).completeIdentityValidation(params);
        }
        catch (error) {
            caughtError = error;
        }
        finally {
            if (caughtError != "") {
                response = caughtError;
            }

            IRAController.storeCurrentTransactionLogs(params, response, "completeIdentityValidation", this.logType, env);
        }

        return response;
    }


    getSrcProfiles = async () => {
        // var profileParams = {};
        // console.log(store.getState().reducer.idTokens);

        // if (profileParams.idTokens == null || profileParams.idTokens == "") {
        //     profileParams.idTokens = store.getState().reducer.idTokens;
        // }

        // // If store doesn't have idtokens
        // if (profileParams.idTokens == null || profileParams.idTokens == "") {
        //     this.srciError.reasonCode = "idtokens missing";
        //     this.srciError.description = "idtokens missing";

        //     return this.srciError;
        // }

        var response = "";


        let selectedEnvironments = await this.getSelectedEnvironments();

        if (selectedEnvironments == null || selectedEnvironments.length <= 0) {
            return this.srciError;
        }


        var maskedCards = [], profileDetails = [], errorResponses = [];
        for (const env of selectedEnvironments) {
            console.log(env);

            let srcProfileParams = await DataParamsController.getParamsForTheAPICall("getSrcProfile", env);

            console.log(srcProfileParams);
            console.log(store.getState().reducer.idTokens);

            if (srcProfileParams.idTokens == "") {
                srcProfileParams.idTokens = store.getState().reducer.idTokens;
            }

            console.log(srcProfileParams);

            response = await this.srcProfileSDKMethodExecution(env, srcProfileParams);

            if (response.srcCorrelationId != null && response.profiles.length > 0) {

                if (maskedCards.length != 0) {
                    maskedCards = response.profiles[0].maskedCards;
                } else {
                    maskedCards.push(response.profiles[0].maskedCards)
                }

                var tempProfile = {
                    srcCorrelationId: response.srcCorrelationId,
                    maskedCards: response.profiles[0].maskedCards,
                    env: env
                };

                profileDetails.push(tempProfile);
            } else {
                errorResponses.push(response);
            }
        }
        store.dispatch(cardList(maskedCards));
        console.log("cp error count " + errorResponses.length);

        if (selectedEnvironments.length == errorResponses.length) {
            return errorResponses[errorResponses.length - 1];
        }

        return profileDetails;
    }

    checkout = async (env, params) => {
        var caughtError = "", response = "";

        if (env == "") {
            let selectedEnvironments = await this.getSelectedEnvironments();

            if (selectedEnvironments == null || selectedEnvironments.length <= 0) {
                return this.srciError;
            }

            env = selectedEnvironments[0];
        }

        try {
            response = await EnvironWrapper.getSDKObject(env).checkout(params);
        }
        catch (error) {
            caughtError = error;
        }
        finally {
            if (caughtError != "") {
                response = caughtError;
            }

            IRAController.storeCurrentTransactionLogs(params, response, "checkout", this.logType, env);
        }

        return response;
    }


    srcProfileSDKMethodExecution = async (env, params) => {
        var caughtError = "";
        try {
            if (params.dpaTransactionOptions != null) {
                delete (params.dpaTransactionOptions);
            }

            var response = await EnvironWrapper.getSDKObject(env).getSrcProfile(params);
        }
        catch (error) {
            caughtError = error;
        }
        finally {
            if (caughtError != "") {
                response = caughtError;
            }

            IRAController.storeCurrentTransactionLogs(params, response, "getSrcProfile", this.logType, env);
        }

        return response;

    }

    // Federated logout
    unBindAppInstance = async (env, idToken) => {
        var caughtError = "";
        var params = { idToken: idToken };

        let selectedEnvironments = await this.getSelectedEnvironments();

        if (selectedEnvironments == null || selectedEnvironments.length <= 0) {
            return this.srciError;
        }


        var errorResponses = [];
        for (const env of selectedEnvironments) {
            try {
                var response = await EnvironWrapper.getSDKObject(env).unbindAppInstance(params);
            }
            catch (error) {
                caughtError = error;
                errorResponses.push(error);
            }
            finally {
                if (caughtError != "") {
                    response = caughtError;
                }

                IRAController.storeCurrentTransactionLogs(params, response, "unbindAppInstance", this.logType, env);
            }

        }

        console.log("unbindApp instance Error count " + errorResponses.length);
        return selectedEnvironments.length != errorResponses.length ? true : errorResponses[errorResponses.length - 1];

    }

    enrollCard = async (card, env) => {
        if (env == "") {
            let selectedEnvironments = await this.getSelectedEnvironments();

            if (selectedEnvironments == null || selectedEnvironments.length <= 0) {
                return this.srciError;
            }

            env = selectedEnvironments[0];
        }

        var cardEnrollment = await DataParamsController.getParamsForTheAPICall("enrollCard", env);
        cardEnrollment.srciTransactionId = SRCWrapper.srcTransactionID;


        // Taking the json editor value if the value exist 
        // or taking the value entered in the UI execution 
        if (DataParamsController.developerMode) {
            card.primaryAccountNumber = cardEnrollment.encryptedCard.card.primaryAccountNumber != "" ? cardEnrollment.encryptedCard.card.primaryAccountNumber
                : card.primaryAccountNumber;
            card.cardSecurityCode = cardEnrollment.encryptedCard.card.cardSecurityCode != "" ? cardEnrollment.encryptedCard.card.cardSecurityCode
                : card.cardSecurityCode;
            card.panExpirationMonth = cardEnrollment.encryptedCard.card.panExpirationMonth != "" ? cardEnrollment.encryptedCard.card.panExpirationMonth
                : card.panExpirationMonth;
            card.panExpirationYear = cardEnrollment.encryptedCard.card.panExpirationYear != "" ? cardEnrollment.encryptedCard.card.panExpirationYear
                : card.panExpirationYear;
        }

        let encryptedCard = await CardEncryption.encrptCardDetails(card, env);

        if (!DataParamsController.developerMode) {
            encryptedCard = cardEnrollment.encryptedCard != "" ?
                cardEnrollment.encryptedCard : encryptedCard
        }

        if (encryptedCard != null && encryptedCard != "") {
            cardEnrollment.encryptedCard = encryptedCard;
        } else {
            cardEnrollment.card = card;
        }

        // For card enrollment, select the first environment beacuse BIN Ranges are same for all environments
        var caughtError = "";

        try {
            var response = await EnvironWrapper.getSDKObject(env).enrollCard(cardEnrollment);
        }
        catch (error) {
            caughtError = error;
        }
        finally {
            if (caughtError != "") {
                response = caughtError;
            }

            IRAController.storeCurrentTransactionLogs(cardEnrollment, response, "enrollCard", this.logType, env);
        }

        return response;
    }
}

export default new SdkMethodWrapper();