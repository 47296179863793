import * as React from 'react';
import { Link } from 'react-router-dom';
import { CheckoutNavBarComponent } from '../../components/CheckoutNavBar';
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import $ from "jquery";
import SRCWrapper from "../../utility/SRCWrapper";
import SRCLogger from "../../logger/SRCLogger";
declare var DGNCheckout: any;


export class PaymentCardDetailsPage extends React.Component<any, any> {
  constructor(props:any) {
    super(props);
  }
  
  enrollCardClick = async () => {
    // enrollCard.params.srciTransactionId = SRCWrapper.srcTransactionID;

    //  console.log(enrollCard.params);
    // // enroll card
    // let enrollCardResponse = await DGNCheckout.enrollCard(
    //   enrollCard.params
    // );

    // console.log(enrollCardResponse);

  };
  render() {
    return (
      <Container fluid={true} className="checkout-page-container p-0">
        <CheckoutNavBarComponent />

        <div className="row pt-2  ">
          <p className="col-6 mt-2 text-left pl-5 payment-card-price-name">Merchant</p>
          <p className="col-6 mt-2 text-right pr-5 payment-card-price">$10.00 <span className="payment-card-price-value">USD</span></p>
        </div><hr className="hr-line mt-0 ml-4 mr-4" />

        <div className="row">
          <p className="col-12 text-left pl-5 payment-card-title-with">Pay with</p>


          <div className="media ml-5 mb-3">
            <img className="align-self-center mr-3" src="discover-atm-card.jpg" width="50" alt="discover atm card" />
            <div className="media-body payment-card-mycard">
              <p className="mt-2 payment-card-mycard-name">Discover Card</p>
              <p className="mb-0 payment-card-mycard-short-number">**** 0945</p>
            </div>
          </div>        </div>


        <hr className="hr-line mt-0 ml-4 mr-4" />

        <div className="row">
          <p className="col-12 text-left pl-5 payment-card-title-with">Billing address</p>
        </div>
        <form className="row mb-3">
          <div className="row px-5">
            <div className="form-group billing-address-form col-6">
              <input type="text" className="form-control py-4" id="firsrtnameInput" aria-describedby="firstnameHelp" placeholder="First Name" />
            </div>

            <div className="form-group billing-address-form col-6 ">
              <input type="text" className="form-control py-4" id="lastnameInput" aria-describedby="lastnameHelp" placeholder="Last Name" />
            </div>

          </div>

          <div className="form-group w-100 billing-address-form px-5">
            <select className="form-control" id="exampleFormControlSelect2" placeholder="Country">
            <option defaultValue="0">Country</option>
              <option>India</option>
            
            </select>
          </div>
        
          <div className="form-group w-100 billing-address-form px-5">
            <input type="text" className="form-control py-4" id="streetaddressInput" aria-describedby="streetaddressHelp" placeholder="Street Address" />
          </div>

          <div className="form-group w-100 billing-address-form px-5">
            <input type="text" className="form-control py-4" id="aptInput" aria-describedby="streetaddressHelp" placeholder="App/Suite (Optional)" />
          </div>

          <div className="form-group w-100 billing-address-form px-5">
            <input type="text" className="form-control py-4" id="aptInput" aria-describedby="streetaddressHelp" placeholder="City" />
          </div>

          <div className="row px-5">
            <div className="form-group billing-address-form col-6">
              <input type="text" className="form-control py-4" id="firsrtnameInput" aria-describedby="firstnameHelp" placeholder="State" />
            </div>

            <div className="form-group billing-address-form col-6 ">
              <input type="text" className="form-control py-4" id="lastnameInput" aria-describedby="lastnameHelp" placeholder="Zip Code" />
            </div>

          </div>


          <div className="row px-5">
            <div className="form-check billing-address-form ml-3">
              <input type="checkbox" className="form-check-input py-4" id="exampleCheck1" />
              <label className="form-check-label" htmlFor="exampleCheck1">Ship to this address</label>
            </div>

          </div>




        </form>

        <hr className="hr-line mt-0 ml-4 mr-4" />

        <div className="row">
          <p className="col-12 text-left pl-5 payment-card-title-with">Create your payment profile</p>
        </div>

        <form className="row mb-1">


          <div className="form-group w-100 billing-address-form px-5">
            <input type="text" className="form-control py-4" id="streetaddressInput" aria-describedby="emailHelp" placeholder="Email Address" />
            <small id="emailHelp" className="form-text text-muted">Your email will be your user ID.</small>

          </div>
          <div className="row px-5">
            <div className="form-group billing-address-form col-4">

            <select className="form-control" id="exampleFormControlSelect2" placeholder="US +1">
            <option defaultValue="0">US +1</option>
              <option>+1</option>
            
            </select>
              
            </div>

            <div className="form-group billing-address-form col-8 ">
              <input type="text" className="form-control py-4" id="lastnameInput" aria-describedby="lastnameHelp" placeholder="Mobile Number" />
            </div>
            <p className="px-4 sms-info">We will send SMS Code(s) to the number you provide to verify your profile. Message and data rates may apply.</p>
          </div>



        </form>
        <hr className="hr-line mt-0 ml-4 mr-4" />

        <form className="row mb-1">

         

          <div className="row px-5">
            <div className="form-check billing-address-form ml-3">
              <input type="checkbox" className="form-check-input py-4" id="exampleCheck1" />
              <label className="form-check-label" htmlFor="exampleCheck1">Yes, send me marketing emails from Discover and its affiliates</label>
            </div>

          </div>

        </form>

        <p className="px-4 sms-info">By clicking Continue, you agree to our Terms and understand your data will be processed according to the Privacy Notice.</p>

        <div className="row btn-continue-row-billing-address pb-3">

          <div className="col-md-12 col-sm-12 align-self-center">
            <div className="checkout-button text-center px-4">

              <Link to='/payment-confirmation' className="link-to">
                <button type="button" onClick={this.enrollCardClick} className="btn btn-continue btn-lg btn-block mt-4">CONTINUE</button>
              </Link>

            </div>

          </div>

        </div>

        <FooterNavBarComponent />
      </Container>);
  }
}
