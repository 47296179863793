export const MERCHANT_NAME = "MERCHANT_NAME";
export const LOADER_STATE = "LOADER_STATE";
export const ID_TOKENS = "ID_TOKENS";
export const SRC_CORRELATION_ID = "SRC_CORRELATION_ID";
export const CARD_LIST = "CARD_LIST";
export const DPA_ID = "DPA_ID";
export const DATA_PARAMS = "DATA_PARAMS";
export const SRC_CLIENT_ID = "SRC_CLIENT_ID";
export const ENVIRONMENT = "ENVIRONMENT";
export const JWT_TOKEN = "JWT_TOKEN";
export const IRA_LOGS = "IRA_LOGS";
export const DCF_URL = "DCF_URL";
export const CURRENT_TRANSACTION_LOGS = "CURRENT_TRANSACTION_LOGS";
export const SERVER_TO_SERVER_API = "SERVER_TO_SERVER_API";
export const SELECTED_ENVIRONMENTS = "SELECTED_ENVIRONMENTS";   
export const USER_DETAILS = "USER_DETAILS";   

export function merchantName(data) {
    return {
        type: MERCHANT_NAME,
        data
    };
}
export function loaderState(data) {
    return {
        type: LOADER_STATE,
        data
    };
}

export function idTokens(data) {
    return {
        type: ID_TOKENS,
        data
    };
}

export function srcCorrelationId(data) {
    return {
        type: SRC_CORRELATION_ID,
        data
    };
}

export function cardList(data) {
    return {
        type: CARD_LIST,
        data
    };
}

export function dpaID(data) {
    return {
        type: DPA_ID,
        data
    };
}

export function dataParams(data) {
    return {
        type: DATA_PARAMS,
        data
    };
}

export function clientID(data) {
    return {
        type: SRC_CLIENT_ID,
        data
    };
}

export function environment(data) {
    return {
        type: ENVIRONMENT,
        data
    };
}

export function jwtToken(data) {
    return {
        type: JWT_TOKEN,
        data
    };
}

export function IRALogs(data) {
    return {
        type: IRA_LOGS,
        data
    };
}

export function DCFUrl(data) {
    return {
        type: DCF_URL,
        data
    };
}

export function CurrentTransactionLogs(data) {
    return {
        type: CURRENT_TRANSACTION_LOGS,
        data
    };
}

export function ServerToServerAPI(data) {
    return {
        type: SERVER_TO_SERVER_API,
        data
    };
}

export function selectedEnvironments(data) {
    return {
        type: SELECTED_ENVIRONMENTS,
        data
    };
}

export function userDetails(data) {
    return {
        type: USER_DETAILS,
        data
    };
}