import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import { ThankyouNavBarComponent } from '../../components/ThankyouNavbar';
import SRCWrapper from "../../utility/SRCWrapper";
import { loggerHost } from "../../logger/loggerConstants";
import html2canvas from "html2canvas";
import { logImage } from "../../utility/utility.js";
import { store } from "../../redux/store";

export class ScenarioPaymentThankyouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorStatus: false,
      cardData: "",
      shippingAddress: ""
    };
    global.dataURL = "";
  }

  componentDidMount() {
    try {
      var cardDetail = store.getState().reducer.cardList;
      var maskedBillingAddress = "";

      if (cardDetail != null) {
        if(cardDetail.maskedBillingAddress != null){
        maskedBillingAddress = cardDetail.maskedBillingAddress.line1 + " " +
          cardDetail.maskedBillingAddress.line2 + " " + cardDetail.maskedBillingAddress.city + " " +
          cardDetail.maskedBillingAddress.state + " " + cardDetail.maskedBillingAddress.countryCode +
          " " + cardDetail.maskedBillingAddress.zip;
        }
      

        var panDetails = "";
        if (cardDetail.digitalCardData.presentationName == null || cardDetail.digitalCardData.presentationName == "") {
          panDetails = cardDetail.panLastFour;
        } else {
          panDetails = cardDetail.digitalCardData.presentationName;
        }

        this.setState({
          cardData: panDetails,
          shippingAddress: maskedBillingAddress
        });
      
      }

      this.sleep(1000);

      // Taking screen-shot
      var htmlCancvas = html2canvas(document.querySelector("#paymentThankyou"), {
        width: 1000,
        height: 600
      })
        .then(function (canvas) {
          global.dataURL = canvas.toDataURL();
          global.dataURL = global.dataURL.replace(/^data:image\/\w+;base64,/, "");
        });
    } catch (error) {


    }

  };

  closeWindow = async () => {
    logImage(SRCWrapper.srcTransactionID, "confirmation", global.dataURL);
    this.sleep(2000);

    if (SRCWrapper.redirection != null && window.opener != null) {
      window.opener.location.href = loggerHost.lithosuri;
      window.close();
      return;
    } else {
      window.location.href = "/checkout";
    }
  }

  sleep(ms) {
    var d = new Date();
    var d2 = null;
    do { d2 = new Date(); }
    while (d2 - d < ms);
  }


  render() {
    return (
      <div id="paymentThankyou">
        <Container fluid={true} className="checkout-page-container">
          <ThankyouNavBarComponent />
          <h2 className="text-center pt-3 pb-0 checkout-page-yourcart-title">Order Confirmed!</h2>
          <h3 className="text-center checkout-page-yourcart-subtitle">Thank you for the order.</h3>
          <hr className="hr-line-dot" />

          <div className="row mb-2">
            <div className="col-md-12 col-sm-12 align-self-center col-border-bottom-line-dot">
              <h4 className="mx-4 p-1 checkout-page-items">ITEMS:</h4>
              <div className="row mb-2">
                <div className="col-6">
                  <img src={loggerHost.referenceAppuri + "godfather.jpg"} width="150" className="checkout-page-cart-item" />
                </div>
                <div className="col-6">
                  <p className="checkout-page-cart-item-name">The Godfather by Mario Puzo</p>

                  <p className="checkout-page-cart-item-jewels">
                    <span className="property-name">Qty:</span> <span className="property-value">1</span></p>
                  <p className="checkout-page-cart-item-jewels">
                    <span className="property-name">In Stock</span> <span className="property-value"></span></p>

                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 align-self-center mt-2 col-border-bottom-line-dot">
              <div className="row">
                <p className="col-12 payment-card-title-with shipping-address-title">SHIPPING TO :</p>

                <div className="col-12 payment-card-title-with shipping-address">{this.state.shippingAddress}</div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 align-self-center mt-2 col-border-bottom-line-dot">
              <div className="row">
                <p className="col-12 payment-card-title-with payment-method-title">PAYMENT METHOD :</p>

                <div className="media payment-method mb-3">
                  <img className="align-self-center mr-3" src={loggerHost.referenceAppuri + "discover-atm-card.jpg"} width="50" alt="discover atm card" />
                  <div className="media-body payment-card-mycard">
                    <div className="mt-3 payment-card-mycard-name">{
                      this.state.cardData}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 align-self-center mt-2 col-border-bottom-line-dot">
              <div className="d-flex checkout-page-cart-item-jewels-price">
                <div className="mr-auto px-3 py-2 ">SUBTOTAL</div>
                <div className="px-3 py-2">$10.00</div>
              </div>

              <div className="d-flex checkout-page-cart-item-jewels-price">
                <div className="mr-auto px-3 py-2">ESTIMATED SHIPPING & <br />HANDLING</div>
                <div className="px-3 py-2">FREE</div>
              </div>

              <div className="d-flex checkout-page-cart-item-jewels-total-price">
                <div className="mr-auto px-3 py-2">TOTAL</div>
                <div className="px-3 py-2">$10.00</div>
              </div>
            </div>
          </div>

          <div className="row btn-continue-row-billing-address pb-3">
            <div className="col-md-12 col-sm-12 align-self-center">
              <div className="checkout-button text-center px-4">
                <a id="hrefID" className="link-to">
                  <button type="button" id="btnThanyou" onClick={this.closeWindow} className="btn btn-continue btn-sm btn-block mt-4">OK</button>
                </a>
              </div>
            </div>
          </div>
          <FooterNavBarComponent />
        </Container>
      </div>);
  }
}