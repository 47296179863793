import {
    LOADER_STATE,
    MERCHANT_NAME,
    ID_TOKENS,
    SRC_CORRELATION_ID,
    CARD_LIST,
    DPA_ID,
    DATA_PARAMS,
    SRC_CLIENT_ID,
    ENVIRONMENT,
    JWT_TOKEN,
    IRA_LOGS,
    DCF_URL,
    CURRENT_TRANSACTION_LOGS,
    SERVER_TO_SERVER_API,
    SELECTED_ENVIRONMENTS,
    USER_DETAILS
} from "../actions/Action";

export default (state = {}, action) => {
    switch (action.type) {
        case MERCHANT_NAME:
            return {
                ...state,
                merchantName: action.data
            };
        case LOADER_STATE:
            return {
                ...state,
                loaderState: action.data
            };

        case ID_TOKENS:
            return {
                ...state,
                idTokens: action.data
            };
        case SRC_CORRELATION_ID:
            return {
                ...state,
                srcCorrelationId: action.data
            };
        case CARD_LIST:
            return {
                ...state,
                cardList: action.data
            };
        case DPA_ID:
            return {
                ...state,
                dpaID: action.data
            };
        case DATA_PARAMS:
            return {
                ...state,
                dataParams: action.data
            };
        case SRC_CLIENT_ID:
            return {
                ...state,
                clientID: action.data
            };
        case ENVIRONMENT:
            return {
                ...state,
                environment: action.data
            };

        case JWT_TOKEN:
            return {
                ...state,
                jwtToken: action.data
            };

        case IRA_LOGS:
            return {
                ...state,
                IRALogs: action.data
            };
        case DCF_URL:
            return {
                ...state,
                DCFUrl: action.data
            };
        case CURRENT_TRANSACTION_LOGS:
            return {
                ...state,
                CurrentTransactionLogs: action.data
            };
        case SERVER_TO_SERVER_API:
            return {
                ...state,
                ServerToServerAPI: action.data
            };

        case SELECTED_ENVIRONMENTS:
            return {
                ...state,
                selectedEnvironments: action.data
            };

        case USER_DETAILS:
            return {
                ...state,
                userDetails: action.data
            };

        default:
            return state;
    }
};