import * as React from 'react';

export class FooterNavBarComponent extends React.Component<any, any> {
  render() {
    return (
      <div className="footer">
        <div className="container mt-4">
          <div className="row">
            <div className="col text-center footer-navbar-links delimiter">
            <a href="https://www.discoverglobalnetwork.com/en-us/what-we-offer/digital-payments/secure-remote-commerce/terms-of-use" target="_blank">Terms and Conditions &nbsp;&nbsp;</a>|  
            <a href="https://www.discoverglobalnetwork.com/en-us/privacy-policy" target="_blank">   Privacy Policy  &nbsp;&nbsp;</a>   
            </div>
          </div>

        </div>
        <div className="inner text-center footer-navbar-copyright mt-3">
          <p>Copyright @2020 <a href="https://www.discoverglobalnetwork.com/en-us/" target="_blank">Discover &nbsp;&nbsp;</a> All Rights Reserved.</p>
        </div>

        {/* <div className="inner text-center footer-navbar-copyright mt-3">
          <p>Powered by <a href="https://www.payhuddle.com/" target="_blank">Payhuddle &nbsp;&nbsp;</a></p>
        </div> */}
      </div>);
  }
}
