import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import { PaymentNavBarComponent } from '../../components/PaymentNavBar';
import $ from 'jquery';
import { Redirect } from "react-router-dom";

export class PaymentOTPPage extends React.Component<any, any> {
  constructor(props :any) {
    super(props);
    this.state = {
      otpError: false,
      response: false
    };
    this.validateFunction = this.validateFunction.bind(this);
  }

  validateFunction() {
    if ($("#otpInput").val() == "") {
      this.setState({ otpError: true });
    }else{
      this.setState({ response: true });
    }
  }

  render() {
    return (
      <div id="otpConfirmation">
        {this.state.response ? <Redirect to='/payment-thankyou' /> : null}

        <Container fluid={true} className="checkout-page-container">
          <PaymentNavBarComponent />
          <div className="row ">
            <p className="col-12 mt-2 text-center payment-confirmation-title">Enter the code sent to <br />a*****n@gmail.com</p>

          </div>

          <form className="row mb-1" id="frmEnterOTP">
            <div className="form-group w-100 billing-address-form  px-5">
              <input type="password" className="form-control text-center py-4"
                id="otpInput" name="otpInput" aria-describedby="streetaddressHelp" placeholder="- - - -" />

              {this.state.otpError ? (
                <small className='validation-msg' >Enter OTP number.</small>
              ) : null}

            </div>
          </form>

          <div className="row btn-continue-row-otp">
            <div className="col-md-12 col-sm-12 align-self-center">
              <div className="checkout-button text-center px-4">

                <button type="button" onClick={this.validateFunction} id="btnPaymentOTP"
                className="btn btn-continue btn-lg btn-block mt-4">CONTINUE</button>
              </div>

            </div>

          </div>
          <FooterNavBarComponent />
        </Container>
      </div>
    );
  }
}
