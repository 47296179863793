import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import BasicLayout from "./layouts/BasicLayout";
import { DashboardPage } from "./pages/dashboard/Dashboard.Page";
import { PaymentProfilePage } from "./pages/payment-profile/PaymentProfile.Page";
import { PaymentCardDetailsPage } from "./pages/payment-card-details/PaymentCardDetails.Page";
import { PaymentOTPPage } from "./pages/payment-otp/PaymentOTP.Page";
import { GuardProvider, GuardedRoute } from "react-router-guards";

/*---------------------------------- SCENARIO-7 ---------------------*/
import { PaymentThankyouPage } from "./pages/scenario_7/PaymentThankyou.Page";
import { PaymentConfirmationPage } from "./pages/scenario_7/PaymentConfirmation.Page";
import { PaymentCardPage } from "./pages/scenario_7/PaymentCard.Page";
import { CheckoutPage } from "./pages/scenario_7/Checkout.Page";

/*---------------------------------- SCENARIO-4 ---------------------*/
import { ConsumerIdentity } from "./pages/scenario_4/ConsumerIdentity.Page";
import { ScenarioFourCheckoutPage } from "./pages/scenario_4/ScenarioFourCheckoutPage";
import { CompleteIdentity } from "./pages/scenario_4/CompleteIdentity.Page";
import { ProfilePage } from "./pages/scenario_4/ProfilePage";
import { ScenarioFourPaymentThankyou } from "./pages/scenario_4/ScenarioFourPaymentThankyou";

/*---------------------------------- SCENARIO-3 ---------------------*/
import { ST_ProfilePage } from "./pages/scenario_3/ST_ProfilePage";
import { ST_CheckoutPage } from "./pages/scenario_3/ST_CheckoutPage";
import { ST_Thankyou } from "./pages/scenario_3/ST_Thankyou";

/* --------------------- Independent Reference App ---------------------*/
import { IRA_Checkout } from "./pages/independent_reference_app/IRA_Checkout";
import { IRA_PaymentCard } from "./pages/independent_reference_app/IRA_PaymentCard";
import { IRA_CompleteIdentity } from "./pages/independent_reference_app/IRA_CompleteIdentity";
import { IRA_ThankYou } from "./pages/independent_reference_app/IRA_ThankYou";
import { IRA_Profile } from "./pages/independent_reference_app/IRA_Profile";
import { IRA_Login } from "./pages/independent_reference_app/IRA_Login";
import { IRA_NewPassword } from "./pages/independent_reference_app/IRA_NewPassword";
import { IRA_AddCardPage } from "./pages/independent_reference_app/IRA_AddCardPage";
import { IRA_ForgotPassword } from "./pages/independent_reference_app/IRA_ForgotPassword";

/*---------------------------------- SCENARIO-5 ---------------------*/
import { SF_PaymentCardPage } from "./pages/scenario_5/SF_PaymentCardPage";
import { SF_CheckoutPage } from "./pages/scenario_5/SF_CheckoutPage";
import { SF_ProfilePage } from "./pages/scenario_5/SF_ProfilePage";
import { SF_Thankyou } from "./pages/scenario_5/SF_Thankyou";
import { checkout } from "./utility/dataConstansts";

import { ScenarioCheckoutPage } from "./pages/Scenarios/Checkout.Page";
import { ScenarioProfilesPage } from "./pages/Scenarios/ProfilePage";
import { ScenarioPaymentCardPage } from "./pages/Scenarios/PaymentCard.Page";
import { ScenarioPaymentThankyouPage } from "./pages/Scenarios/PaymentThankyou.Page";
import { ScenarioInitiateIdentity } from "./pages/Scenarios/ConsumerIdentity.Page";
import { SecnarioCompleteIdentity } from "./pages/Scenarios/CompleteIdentity.Page";

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <BasicLayout layoutContent={IRA_Login} />}
        />
        <Route
          exact
          path="/landingPage/"
          render={(props) => <BasicLayout layoutContent={IRA_Login} />}
        />
        /*---------------------------------- SCENARIO-7 ---------------------*/
        <Route
          exact
          path="/scenario_7/checkout"
          render={(props) => <BasicLayout layoutContent={CheckoutPage} />}
        />
        <Route
          exact
          path="/scenario_7/payment-card"
          render={(props) => <BasicLayout layoutContent={PaymentCardPage} />}
        />
        <Route
          exact
          path="/scenario_7/payment-card-details"
          render={(props) => (
            <BasicLayout layoutContent={PaymentCardDetailsPage} />
          )}
        />
        <Route
          exact
          path="/scenario_7/payment-confirmation"
          render={(props) => (
            <BasicLayout layoutContent={PaymentConfirmationPage} />
          )}
        />
        <Route
          exact
          path="/scenario_7/payment-profile"
          render={(props) => <BasicLayout layoutContent={PaymentProfilePage} />}
        />
        <Route
          exact
          path="/scenario_7/payment-otp"
          render={(props) => <BasicLayout layoutContent={PaymentOTPPage} />}
        />
        <Route
          exact
          path="/scenario_7/payment-thankyou"
          render={(props) => (
            <BasicLayout layoutContent={PaymentThankyouPage} />
          )}
        />
        /*---------------------------------- SCENARIO-4 ---------------------*/
        <Route
          exact
          path="/scenario_4/checkout"
          render={(props) => (
            <BasicLayout layoutContent={ScenarioFourCheckoutPage} />
          )}
        />
        <Route
          exact
          path="/scenario_4/initiateIdentity"
          render={(props) => <BasicLayout layoutContent={ConsumerIdentity} />}
        />
        <Route
          exact
          path="/scenario_4/completeIdentity"
          render={(props) => <BasicLayout layoutContent={CompleteIdentity} />}
        />
        <Route
          exact
          path="/scenario_4/profiles"
          render={(props) => <BasicLayout layoutContent={ProfilePage} />}
        />
        <Route
          exact
          path="/scenario_4/thank-you"
          render={(props) => (
            <BasicLayout layoutContent={ScenarioFourPaymentThankyou} />
          )}
        />
        /*---------------------------------- SCENARIO-3 ---------------------*/
        <Route
          exact
          path="/scenario_3/checkout"
          render={(props) => <BasicLayout layoutContent={ST_CheckoutPage} />}
        />
        <Route
          exact
          path="/scenario_3/thank-you"
          render={(props) => <BasicLayout layoutContent={ST_Thankyou} />}
        />
        <Route
          exact
          path="/scenario_3/profiles"
          render={(props) => <BasicLayout layoutContent={ST_ProfilePage} />}
        />
        <Route
          exact
          path="/addCard"
          render={(props) => <BasicLayout layoutContent={SF_PaymentCardPage} />}
        />
        /*---------------------------------- SCENARIO-5 ---------------------*/
        <Route
          exact
          path="/scenario_5/payment-card"
          render={(props) => <BasicLayout layoutContent={SF_PaymentCardPage} />}
        />
        <Route
          exact
          path="/scenario_5/profiles"
          render={(props) => <BasicLayout layoutContent={SF_ProfilePage} />}
        />
        <Route
          exact
          path="/scenario_5/checkout"
          render={(props) => <BasicLayout layoutContent={SF_CheckoutPage} />}
        />
        <Route
          exact
          path="/scenario_5/thank-you"
          render={(props) => <BasicLayout layoutContent={SF_Thankyou} />}
        />
        /* --------------------- Independent Reference App
        ---------------------*/
        <Route
          exact
          path="/referenceApp/checkout/"
          render={(props) => (
            <BasicLayout layoutContent={IRA_Checkout} {...props} />
          )}
        />
        <Route
          exact
          path="/referenceApp/signin/"
          render={(props) => <BasicLayout layoutContent={IRA_Login} />}
        />
        <Route
          exact
          path="/referenceApp/add-new-card/"
          render={(props) => <BasicLayout layoutContent={IRA_AddCardPage} />}
        />
        <Route
          exact
          path="/referenceApp/new-password"
          render={(props) => <BasicLayout layoutContent={IRA_NewPassword} />}
        />
        <Route
          exact
          path="/referenceApp/thank-you/"
          render={(props) => <BasicLayout layoutContent={IRA_ThankYou} />}
        />
        <Route
          exact
          path="/referenceApp/completeIdentity/"
          render={(props) => (
            <BasicLayout layoutContent={IRA_CompleteIdentity} />
          )}
        />
        <Route
          exact
          path="/referenceApp/payment-card"
          render={(props) => <BasicLayout layoutContent={IRA_PaymentCard} />}
        />
        <Route
          exact
          path="/referenceApp/profile"
          render={(props) => <BasicLayout layoutContent={IRA_Profile} />}
        />
        <Route
          exact
          path="/referenceApp/forgot-password/"
          render={(props) => <BasicLayout layoutContent={IRA_ForgotPassword} />}
        />
        <Route
          exact
          path="/checkout"
          render={(props) => <BasicLayout layoutContent={IRA_Checkout} />}
        />
        <Route
          exact
          path="/profiles"
          render={(props) => <BasicLayout layoutContent={IRA_Profile} />}
        />
        <Route
          exact
          path="/payment-card"
          render={(props) => <BasicLayout layoutContent={IRA_PaymentCard} />}
        />
        <Route
          exact
          path="/thank-you"
          render={(props) => <BasicLayout layoutContent={IRA_ThankYou} />}
        />
        <Route
          exact
          path="/initiate-identity"
          render={(props) => <BasicLayout layoutContent={IRA_PaymentCard} />}
        />
        <Route
          exact
          path="/complete-identity"
          render={(props) => (
            <BasicLayout layoutContent={IRA_CompleteIdentity} />
          )}
        />
      </Switch>
    );
  }
}
