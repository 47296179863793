import React, { Component } from "react";
import Sidebar from "react-sidebar";
import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";
import { loggerHost } from "../logger/loggerConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { SideBarComponent } from "./SidebarMenu";

export class NavBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  render() {
    return (
      <Navbar bg="white" className="bg-div NavBar">
        <Navbar.Brand>
          <SideBarComponent />
          <h6 className="navBrand"> Reference Application </h6>
        </Navbar.Brand>
        <Nav className="ml-auto logoRight">
          <img
            className="payhuddleLogo"
            src={loggerHost.referenceAppuri + "logo.png"}
            width="150"
          />
        </Nav>
      </Navbar>
    );
  }
}
