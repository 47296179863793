class EnvironWrapper {

    set selectedEnvs(envs) {
        this._selectedEnvs = envs;
    };

    get selectedEnvs() {
        return this._selectedEnvs;
    };

    constructor() {
        this.table = {};
    }

    addEnviron(name, url, callback) {
        var envs = [];

        if (this.selectedEnvs != null) {
            envs = this.selectedEnvs;
        }

        if (!envs.includes(name)) {
            envs.push(name);
            this.selectedEnvs = envs;
        }

        const script = document.createElement("script");
        script.src = url;
        script.id = url;
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            this.setSDKObject(name, window.DGNCheckout)
            if (callback)
                callback();
        };
    }

    isEnvironLoaded(name) {
        return this.table[name] != null;
    }

    setSDKObject(key, value) {
        if (key != null && value != null) {
            this.table[key] = value;
            console.log("value is set");
            return true;
        }
        return false;
    }

    removeSDKobject(name) {
        if (this.isEnvironLoaded(name)) {
            delete (this.table[name]);

            if (this.selectedEnvs != null) {
                var index = this.selectedEnvs.indexOf(name);
                if (index > -1) {
                    this.selectedEnvs.splice(index, 1)
                }
            }
            console.log("value removed");
        }
    }

    getSDKObject(name) {
        if (this.isEnvironLoaded(name)) {
            console.log("value found");
            return this.table[name];
        }
        else {
            console.log("value not found");
            return null;
        }
    }

}

export default new EnvironWrapper();

