import React from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { render } from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import "./scss/_media.css";
import "./scss/_fonts.css";
import "./scss/_custom.css";
import { store } from  "./redux/store"

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

