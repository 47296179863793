import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import { PaymentNavBarComponent } from '../../components/PaymentNavBar';
import SRCWrapper from "../../utility/SRCWrapper";
import { Redirect } from "react-router-dom";
import $ from 'jquery';
import { store } from "../../redux/store";
import { loaderState, cardList, srcCorrelationId } from "../../redux/actions/Action";
import { loggerHost } from "../../logger/loggerConstants";
import "../../scss/_inputBox.css";
import DataParamsController from "../../Controllers/DataParamsController";
import { Col, Row } from "reactstrap";
import CardEncryption from "../../utility/CardEncryption";
import { Auth } from 'aws-amplify';
import { LogWindow } from '../../components/LogWindow';
import { NavBarComponent } from '../../components/NavBar';
import IRAController from "../../Controllers/IRAController";
import ServerToServerAPIController from "../../Controllers/ServerToServerAPIController";
import SdkMethodWrapper from "../../utility/SdkMethodWrapper";

export class IRA_AddCardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumberInput: "",
      expiryMonth: "",
      expiryYear: "",
      cvvNo: "",
      validationErrorCardNo: false,
      validationErrorExpiryDate: false,
      validationErrorCvvNo: false,
      transactionStatus: false,
      errorResponse: false,
      errorMessage: "",
      lithosuri: loggerHost.lithosuri,
      s4Redirection: false,
      noWindowRef: false,
      blankWindowRef: false,
      contentWindow: true,
      dcfInvokeStatus: false,
      emptyWindowRef: false,
      scenarioRefURL: "",
      scenarioRoute: "",
      dcfInvokeMsg: "",
      isUserSignedIn: true,
      switchConsumer: false,
      completeTransaction: false,
      getProfiles: false,
      homePageRedirection: false,
      chosenEnv: ""
    };
  }

  enrollCardClick = async () => {
    var cardNumber = $("#cardNumberInput").text();
    var cardExpTxt = $("#cardExpiryInput").text();
    var splitedString = cardExpTxt.split("/");

    var exMonth = splitedString[0];
    var exYear = splitedString[1];

    // Card CVV
    var cardCvvTxt = $("#cardSCodeInput").text();

    store.dispatch(loaderState(true));

    var card = {};

    card.primaryAccountNumber = cardNumber.replace(/\s/g, '');
    card.panExpirationMonth = exMonth;
    card.panExpirationYear = exYear;
    card.cardSecurityCode = cardCvvTxt;

    let selectedEnvironments = await SdkMethodWrapper.getSelectedEnvironments();

    if (selectedEnvironments == null || selectedEnvironments.length < 0) {
      store.dispatch(loaderState(false));
      this.setState({
        errorStatus: true,
        errorMessage: "Network error. Please try after sometime"
      });
      return;
    }

    this.setState({ chosenEnv: selectedEnvironments[0] });
    let response = "";

    // Card Enrollment
    this.setState({ errorStatus: false });
    response = await SdkMethodWrapper.enrollCard(card, this.state.chosenEnv);
    this.setState({ errorStatus: false });

    if (response.maskedCard != null) {
      store.dispatch(cardList(response.maskedCard));
      this.checkout(response);
    } else {
      {
        store.dispatch(loaderState(false));
        this.setState({
          errorStatus: true,
          errorMessage: (response.description != null) ? response.description : "Please enter correct card information"
        });
        return;
      }
    }
  };

  selectDcfInvoke = dcfInvokeType => {
    let type = dcfInvokeType.target.value;
    this.setDCFState(type);

  };

  setDCFState = async (type) => {
    switch (type) {
      case "windowrefless":
        this.setState({ noWindowRef: true, blankWindowRef: false, contentWindow: false, dcfInvokeStatus: false, emptyWindowRef: false });
        break;

      case "blank":
        this.setState({ blankWindowRef: true, noWindowRef: false, contentWindow: false, dcfInvokeStatus: false, emptyWindowRef: false });
        break;

      case "content":
        this.setState({ contentWindow: true, noWindowRef: false, blankWindowRef: false, dcfInvokeStatus: false, emptyWindowRef: false });
        break;

      case "invalid":
        this.setState({ contentWindow: false, noWindowRef: false, blankWindowRef: false, emptyWindowRef: true, dcfInvokeStatus: false });
        break;

      default:
        this.setState({ contentWindow: false, noWindowRef: false, blankWindowRef: false, emptyWindowRef: true, dcfInvokeStatus: false });
        break;
    }
  }


  directCheckout = async () => {
    this.checkout(null);
  };


  checkout = async (enrollCardResponse) => {
    console.log(this.state.chosenEnv);

    if (this.state.chosenEnv == "") {
      let selectedEnvironments = await SdkMethodWrapper.getSelectedEnvironments();

      if (selectedEnvironments == null || selectedEnvironments.length <= 0) {
        store.dispatch(loaderState(false));
        this.setState({
          errorStatus: true,
          errorMessage: "Network error. Please try after sometime."
        });
        return;
      }
      this.setState({ chosenEnv: selectedEnvironments[0] });
    }

    var dcfInvokeType = DataParamsController.getWindowRefParams(this.state.chosenEnv);
    this.setDCFState(dcfInvokeType);

    var checkout = await DataParamsController.getParamsForTheAPICall("checkout", this.state.chosenEnv);

    const { location: { state: { response: { dcfActionCode } } } } =
      this.props.location && this.props.location.state &&
        this.props.location.state.response ?
        this.props :
        { location: { state: { response: { dcfActionCode: null } } } };
    let dcfWindow;

    if (enrollCardResponse != null) {
      checkout.srcCorrelationId = enrollCardResponse.srcCorrelationId;
      checkout.srcDigitalCardId = enrollCardResponse.maskedCard.srcDigitalCardId;

      store.dispatch(srcCorrelationId(checkout.srcCorrelationId));
    } else { // Direct checkout
      var cardNumber = $("#cardNumberInput").text();
      var cardExpTxt = $("#cardExpiryInput").text();
      var splitedString = cardExpTxt.split("/");
      var exMonth = splitedString[0];
      var exYear = splitedString[1];
      var cardCvvTxt = $("#cardSCodeInput").text();

      var card = {};

      card.primaryAccountNumber = cardNumber.replace(/\s/g, '');
      card.panExpirationMonth = exMonth;
      card.panExpirationYear = exYear;
      card.cardSecurityCode = cardCvvTxt;

      let encryptedCard = await CardEncryption.encrptCardDetails(card, this.state.chosenEnv);

      if (encryptedCard != null && encryptedCard != "") {
        checkout.encryptedCard = encryptedCard;
      } else {
        checkout.card = card;
      }
    }

    checkout.srciTransactionId = SRCWrapper.srcTransactionID;

    let value = {};
    if (this.state.contentWindow === true) {
      let dcfIframe = document.createElement('iframe');
      dcfIframe.setAttribute('id', 'dcfIframe');
      dcfIframe.setAttribute('src', '');
      dcfIframe.style.zIndex = '2';
      dcfIframe.style.position = "absolute";
      dcfIframe.style.display = 'block';
      dcfIframe.style.width = '30%';
      dcfIframe.style.height = '92vh';
      dcfIframe.style.margin = '2% 35%';
      document.body.after(dcfIframe, document.body);
      checkout.windowRef = document.getElementById('dcfIframe').contentWindow;
    }

    if (this.state.blankWindowRef === true) {
      dcfWindow = window.open('about:blank');
      checkout.windowRef = dcfWindow;
    }


    if (this.state.noWindowRef === true) {
      delete (checkout.windowRef);
    }

    // Work around fix for UI responsiveness
    document.body.style.overflowY = "hidden";

    let response = "";
    var checkoutCaughtError = "";
    try {
      if (checkout.srciActionCode != null) {
        console.log("-- delete--");
        delete (checkout.srciActionCode);
      }

      console.log("SDK Checkout params for returning user -add card from SRCI")
      console.log(checkout);

      response = await SdkMethodWrapper.checkout(this.state.chosenEnv, checkout);

      if (this.state.blankWindowRef) {
        dcfWindow.close();
      }

      if (this.state.contentWindow) {
        var frame = document.getElementById("dcfIframe");
        frame.parentNode.removeChild(frame);
      }

      if (response.description != null || response.reason != null) {
        this.setState({
          errorStatus: true,
          errorMessage: response.description
        });

        store.dispatch(loaderState(false));
        return;
      }


    } catch (error) {
      console.log("SDK catch error: ", error);
      store.dispatch(loaderState(false));
    } finally {
      if (this.state.errorStatus) {
        response = checkoutCaughtError;
      }

      if (response.idToken != null && response.unbindAppInstance == true) {
        await SdkMethodWrapper.unBindAppInstance(this.state.chosenEnv, response.idToken)
      }

      // Work around fix for UI responsiveness
      document.body.style.overflowY = "auto";

      if (response.dcfActionCode == "COMPLETE" || response.dcfActionCode == "ERROR") {
        var decodedPayload = await ServerToServerAPIController.transactionCredentialsAPI(checkout, response, this.state.chosenEnv);

        store.dispatch(cardList(decodedPayload));
      }

      if (this.state.errorStatus == false) {
        switch (response.dcfActionCode) {
          case "COMPLETE":
            this.setState({ completeTransaction: true });
            break;

          case "ADD_CARD":
            await this.componentDidMount();
            this.setState({
              addCard: true, cardNumberInput: "",
              expiryMonth: "",
              expiryYear: "",
              cvvNo: "",
            });
            break;

          case "SWITCH_CONSUMER":
            this.setState({ switchConsumer: true });
            break;

          case "CHANGE_CARD":
            this.setState({ getProfiles: true });
            break;

          default: // CANCEL || ERROR

            if (response.dcfActionCode == "ERROR") {
              this.setState({
                errorStatus: true,
                errorResponse: false,
                errorMessage: response.error.errorCode,
                errorDescription: response.error.errorDescription
              });
            }

            IRAController.loadCheckoutPage(this.state.errorStatus);
        }
      }
      store.dispatch(loaderState(false));
    }
  };



  onClickConfigureButton = async () => {
    $(".configWindow #react-burger-menu-btn").click();
  };

  async componentDidMount() {
    console.log("Component did mount");
    Auth.currentAuthenticatedUser()
      .then(user => this.setState({ isUserSignedIn: true }))
      .catch(err => this.setState({ isUserSignedIn: false }));

    $("#cardNumberInput, #cardExpiryInput, #cardSCodeInput").text("");

    var dcfInvokeType = DataParamsController.getWindowRefParams();

    this.setDCFState(dcfInvokeType);

    store.dispatch(loaderState(false));
  }

  render() {
    return (
      <div id="paymentCardPage" className="addNewCard">
        <NavBarComponent />

        {this.state.completeTransaction ? <Redirect to='/referenceApp/thank-you' /> : null}

        {
          this.state.switchConsumer === true ? (
            <Redirect to="/referenceApp/payment-card?switch_consumer" />
          ) : null
        }

        {this.state.getProfiles ? <Redirect to='/referenceApp/profile' /> : null}

        {
          this.state.homePageRedirection === true ? (
            <Redirect
              to={{
                pathname: "/referenceApp/checkout",
                state: { errorDescription: this.state.errorDescription }
              }}
            />
          ) : null
        }

        <Container fluid>
          <Row>
            <Col md={4} className="checkout-container">
              <div className="checkout-page-container page-container checkout-page-border checkout-box2">
                <PaymentNavBarComponent />
                <h2 className="text-center pt-3 paymentcard-page-title">We secure your information<br /></h2>
                <p className="text-center paymentcard-page-subtitle colorOrange" >Together We Work</p>
                <div className="row text-center card-details-container">
                </div>
                <div className="card-details-container-inner">
                  <div className="row card-details">
                    <div className="col-12">
                      <p className="px-2 pt-3 pb-1 card-details-title">Enter your card details</p>
                    </div>
                  </div>
                  <form className="row card-details-form" id="frmCardDetails">
                    <div className="form-group w-100 px-4 card-details-form">
                      {/* div input for screen-shot caputring with added data  */}
                      <div className="single-line inputBox py-4 form-control" contenteditable="true" data-max-length="20"
                        name="cardNumberInput"
                        id="cardNumberInput" aria-describedby="cardNumberHelp" placeholder="Card Number"
                        autoComplete='off'
                        value={this.state.cardNumberInput}>
                      </div>
                      {this.state.validationErrorCardNo ? (
                        <small className='validation-msg' >Enter valid card number.</small>
                      ) : null}
                    </div>
                    <div className="row px-4 col-md-12">
                      <div className="form-group  card-details-form col-6">
                        {/* div input for screen-shot caputring with added data  */}
                        <div className="single-line smallBox form-control py-4" contenteditable="true" data-max-length="10"
                          value={this.state.cardExpiryInput}
                          name="cardExpiryInput" id="cardExpiryInput" aria-describedby="cardExpiryrHelp"
                          placeholder="MM/YYYY" >
                        </div>

                        {this.state.validationErrorExpiryDate ? (
                          <small className='validation-msg'>Enter valid expiry month and year(MM/YYYY)</small>
                        ) : null}
                      </div>

                      <div className="form-group card-details-form col-6">

                        {/* div input for screen-shot caputring with added data  */}
                        <div className="single-line smallBox form-control py-4" contenteditable="true" data-max-length="10"
                          value={this.state.cardSCodeInput}
                          name="cardSecurityCodeInput" id="cardSCodeInput"
                          aria-describedby="cardSCodeHelp" placeholder="Security Code"
                        >
                        </div>
                        {this.state.validationErrorCvvNo ? (
                          <small className='validation-msg'>Enter valid security no</small>
                        ) : null}

                      </div>
                      {this.state.errorStatus ? (
                        <small className='validation-msg'>{this.state.errorMessage}</small>
                      ) : null}
                    </div>
                  </form>

                  <div className="row btn-continue-row pb-3">
                    <div className="col-md-12 col-sm-12 align-self-center">
                      <div className="checkout-button text-center px-4 btn-enrollContinue">
                        <button type="button" onClick={this.enrollCardClick} id="btnPaymentCard"
                          className="btn btn-continue btn-lg btn-block mt-2 ">Enroll & Continue</button>
                      </div>
                      <div className="checkout-button text-center px-4 btnDirectCheckout">
                        <button type="button" onClick={this.directCheckout} id="btnDirectCheckout"
                          className="btn btn-continue btn-lg btn-block mt-2">Direct Checkout</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="checkout-box2 FooterCheckout">
                <FooterNavBarComponent />
              </div>

            </Col>
            <Col md={8} className="LogWindow">
              <LogWindow />
            </Col>
          </Row>
        </Container>
      </div>

    );
  }
}