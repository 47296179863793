import * as React from 'react';
import {  Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import { PaymentNavBarComponent } from '../../components/PaymentNavBar';
import { store } from "../../redux/store";
import { loaderState } from "../../redux/actions/Action";

export class PaymentProfilePage extends React.Component<any, any> {
    
  componentDidMount() {
    store.dispatch(loaderState(false));
  }
  
  render() {
      return (
        <Container fluid={true} className="checkout-page-container">
        <PaymentNavBarComponent />
        <div className="row ">
        <p className="col-12 mt-2 text-center payment-confirmation-title">Enter the email and mobile no.</p>
       
        </div>

        <form className="row mb-1">

        
        <div className="form-group w-100 billing-address-form px-5">
        <input type="text" className="form-control py-4" id="streetaddressInput" aria-describedby="emailHelp" placeholder="Email Address" />
        <small id="emailHelp" className="form-text text-muted">Your email will be your user ID.</small>

      </div>
      <div className="row px-5">
        <div className="form-group billing-address-form col-4">

        <select className="form-control" id="exampleFormControlSelect2" placeholder="US +1">
        <option value="0" defaultValue="0">US +1</option>
          <option value="1">+1</option>
        
        </select>
          
        </div>

        <div className="form-group billing-address-form col-8 ">
          <input type="text" className="form-control py-4" id="lastnameInput" aria-describedby="lastnameHelp" placeholder="Mobile Number" />
        </div>
        <p className="px-4 sms-info">We will send SMS Code(s) to the number you provide to verify your profile. Message and data rates may apply.</p>
      </div>



        </form>
    
          <div className="row btn-continue-row-billing-address pb-3">

          <div className="col-md-12 col-sm-12 align-self-center">
            <div className="checkout-button text-center px-4">

            <Link to='/payment-thankyou' className="link-to">
            <button type="button" id="btnPaymentProfile"
            
            className="btn btn-continue btn-lg btn-block mt-4">CONTINUE</button>
            </Link>
            </div>

          </div>

        </div>
        <FooterNavBarComponent />
        </Container>);
    }
  }
