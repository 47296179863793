import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import { CheckoutNavBarComponent } from '../../components/CheckoutNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import html2canvas from "html2canvas";
import { logImage } from "../../utility/utility.js";
import SRCWrapper from "../../utility/SRCWrapper";
import { store } from "../../redux/store";
import { loaderState } from "../../redux/actions/Action";

export class PaymentConfirmationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMsg: false
    };
  }

  paymentConfirmation() {
    // Taking screen-shot
    var dataURL;

    try {
      html2canvas(document.querySelector('#confirmation'), {
        width: 1000,
        height: 600
      })
        .then(function (canvas) {
          dataURL = canvas.toDataURL();
          dataURL = dataURL.replace(/^data:image\/\w+;base64,/, "");
        });
      console.log(SRCWrapper.srcTransactionID);
      let response = logImage(SRCWrapper.srcTransactionID, "confirmation", dataURL);
    } catch (error) {

    }
  }
  componentDidMount() {
    store.dispatch(loaderState(false));
  }

  render() {
    return (
      <div id="confirmation">
        <Container fluid={true} className="checkout-page-container p-0">
          <CheckoutNavBarComponent />

          <div className="row pt-2  ">
            <p className="col-6 mt-2 text-left pl-5 payment-card-price-name">Merchant</p>
            <p className="col-6 mt-2 text-right pr-5 payment-card-price">$10.00 <span className="payment-card-price-value">USD</span></p>
          </div><hr className="hr-line mt-0 ml-4 mr-4" />

          <div className="row">
            <p className="col-3 text-left pl-5 white-space-nowrap payment-card-title-with">Pay with</p>

            <div className="col-9">
              <div className="media  mb-3 w-100">

                <img className="align-self-center mr-2" src="discover-atm-card.jpg" width="35" alt="discover atm card" />
                <div className="media-body payment-card-mycard position-relative">
                  <p className="mt-2 payment-card-mycard-name">Discover Card</p>
                  <p className="mb-0 payment-card-mycard-short-number">**** 0945</p>
                  <p className="float-right p-0 m-0 position-absolute payment-card-mycard-link">
                    <FontAwesomeIcon icon={faChevronRight} /></p>
                </div>
              </div>

            </div>

          </div>

          <hr className="hr-line mt-0 ml-4 mr-4" />
          <div className="row">
            <p className="col-3 text-left pl-5 white-space-nowrap payment-card-title-with">Ship To</p>

            <div className="col-9">
              <div className="media  mb-3 w-100">

                <div className="media-body payment-card-mycard position-relative">
                  <p className="mt-2 payment-card-mycard-name">Natasha</p>
                  <p className="mb-0 payment-card-mycard-short-number">2500 Lake Cook Rd, IL 60015</p>
                  <p className="float-right p-0 m-0 position-absolute payment-card-mycard-link">
                    <FontAwesomeIcon icon={faChevronRight} /></p>
                </div>
              </div>

            </div>

          </div>

          <hr className="hr-line mt-0 ml-4 mr-4" />

          <form className="row mb-1">

          </form>
          <div className="row btn-continue-row-billing-address pb-3">

            <div className="col-md-12 col-sm-12 align-self-center">
              <div className="checkout-button text-center px-4">

                <a href="/scenario_7/payment-thankyou">
                  <button type="button" onClick={this.paymentConfirmation} className="btn btn-continue btn-lg btn-block mt-4">CONFIRM</button>
                </a>
              </div>

            </div>

          </div>
          <FooterNavBarComponent />
        </Container>
      </div>
    );
  }
}
