import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import { PaymentNavBarComponent } from '../../components/PaymentNavBar';
import SRCWrapper from "../../utility/SRCWrapper";
import { Redirect } from "react-router-dom";
import html2canvas from "html2canvas";
import { logImage } from "../../utility/utility.js";
import $ from 'jquery';
import { store } from "../../redux/store";
import { loaderState, cardList } from "../../redux/actions/Action";
import { loggerHost } from "../../logger/loggerConstants";
import "../../scss/_inputBox.css";
import DataParamsController from "../../Controllers/DataParamsController";
import { Input, Col, Label } from "reactstrap";
import CardEncryption from "../../utility/CardEncryption";

var testCaseName = "";

export class ScenarioPaymentCardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumberInput: "",
      expiryMonth: "",
      expiryYear: "",
      cvvNo: "",
      validationErrorCardNo: false,
      validationErrorExpiryDate: false,
      validationErrorCvvNo: false,
      errorResponse: false,
      errorMessage: "",
      testCaseName: "",
      lithosuri: loggerHost.lithosuri,
      s4Redirection: false,
      noWindowRef: false,
      blankWindowRef: false,
      contentWindow: true,
      dcfInvokeStatus: false,
      emptyWindowRef: false,
      dcfInvokeMsg: ""
    };
  }



  enrollCardClick = async () => {
    var cardNumber = $("#cardNumberInput").text();
    var cardExpTxt = $("#cardExpiryInput").text();
    var splitedString = cardExpTxt.split("/");

    var exMonth = splitedString[0];
    var exYear = splitedString[1];

    // Card CVV
    var cardCvvTxt = $("#cardSCodeInput").text();

    var cardEnrollment = await DataParamsController.getParamsForTheAPICall("enrollCard");
    cardEnrollment.srciTransactionId = SRCWrapper.srcTransactionID;

    try {
      // Taking screen-shot
      var dataURL;
      //var element = document.querySelector('#paymentCardPage') as HTMLDivElement;
      html2canvas(document.querySelector('#paymentCardPage'), {
        width: 1000,
        height: 600,

      }).then(function (canvas) {
        console.log(canvas);
        //canvas.innerText();

        dataURL = canvas.toDataURL();
        dataURL = dataURL.replace(/^data:image\/\w+;base64,/, "");
      });
    } catch (error) {
      // Error implementation
    }

    store.dispatch(loaderState(true));

    var card = {};

    card.primaryAccountNumber = cardNumber.replace(/\s/g, '');
    card.panExpirationMonth = exMonth;
    card.panExpirationYear = exYear;
    card.cardSecurityCode = cardCvvTxt;

    let encryptedCard = await CardEncryption.encrptCardDetails(card);

    if (encryptedCard != null && encryptedCard != "") {
      cardEnrollment.encryptedCard = encryptedCard;
    } else {
      cardEnrollment.card = card;
    }
    console.log(cardEnrollment);
    try {
      // Card Enrollment
      console.log("----------------------");
      let response = await window.DGNCheckout.enrollCard(cardEnrollment);
      console.log(response);

      store.dispatch(cardList(response.maskedCard));

      logImage(SRCWrapper.srcTransactionID, "enrollCard", dataURL); // Sending screen shot image 
      this.checkout(response);

    } catch (error) {
      console.log(error.description);
      this.setState({
        errorStatus: true,
        errorMessage: (error.description != null) ? error.description : "Please enter correct details"
      });

      store.dispatch(loaderState(false));
    }

  };

  selectDcfInvoke = dcfInvokeType => {
    let type = dcfInvokeType.target.value;
    console.log(type);

    switch (type) {
      case "noWindowRef":
        this.setState({ noWindowRef: true, blankWindowRef: false, contentWindow: false, dcfInvokeStatus: false, emptyWindowRef: false });
        break;

      case "blank":
        this.setState({ blankWindowRef: true, noWindowRef: false, contentWindow: false, dcfInvokeStatus: false, emptyWindowRef: false });
        break;

      case "contentWindow":
        this.setState({ contentWindow: true, noWindowRef: false, blankWindowRef: false, dcfInvokeStatus: false, emptyWindowRef: false });
        break;

      case "emptyWindowRef":
        this.setState({ contentWindow: false, noWindowRef: false, blankWindowRef: false, emptyWindowRef: true, dcfInvokeStatus: false });
        break;

      default:
        break;
    }
  };


  directCheckout = async () => {
    store.dispatch(loaderState(true));

    this.checkout(null);
  };


  checkout = async (enrollCardResponse) => {
    var checkout = await DataParamsController.getParamsForTheAPICall("checkout");
    console.log(checkout);

    const { location: { state: { response: { dcfActionCode } } } } =
      this.props.location && this.props.location.state &&
        this.props.location.state.response ?
        this.props :
        { location: { state: { response: { dcfActionCode: null } } } };

    let dcfWindow;

    if (enrollCardResponse != null) {
      checkout.srcCorrelationId = enrollCardResponse.srcCorrelationId;
      checkout.srcDigitalCardId = enrollCardResponse.maskedCard.srcDigitalCardId;

    } else { // Direct checkout
      var cardNumber = $("#cardNumberInput").text();
      var cardExpTxt = $("#cardExpiryInput").text();
      var splitedString = cardExpTxt.split("/");
      var exMonth = splitedString[0];
      var exYear = splitedString[1];
      var cardCvvTxt = $("#cardSCodeInput").text();

      var card = {};

      card.primaryAccountNumber = cardNumber.replace(/\s/g, '');
      card.panExpirationMonth = exMonth;
      card.panExpirationYear = exYear;
      card.cardSecurityCode = cardCvvTxt;

      let encryptedCard = await CardEncryption.encrptCardDetails(card);

      if (encryptedCard != null && encryptedCard != "") {
        checkout.encryptedCard = encryptedCard;
      }

      console.log("direct checkout params ")
      console.log(checkout);
    }

    checkout.srciActionCode = "NEW_USER";
    checkout.srciTransactionId = SRCWrapper.srcTransactionID;

    let value = {};
    if (this.state.contentWindow === true) {
      let dcfIframe = document.createElement('iframe');
      dcfIframe.setAttribute('id', 'dcfIframe');
      dcfIframe.setAttribute('name', 'DCFIframe');
      dcfIframe.setAttribute('src', '');
      dcfIframe.style.zIndex = '2';
      dcfIframe.style.position = "absolute";
      dcfIframe.style.display = 'block';
      dcfIframe.style.width = '30%';
      dcfIframe.style.height = '92vh';
      dcfIframe.style.margin = '2% 35%';
      document.body.after(dcfIframe, document.body);
      checkout.windowRef = document.getElementById('dcfIframe').contentWindow;
    }

    if (this.state.blankWindowRef === true) {
      dcfWindow = window.open('about:blank');
      checkout.windowRef = dcfWindow;
    }


    console.log(checkout);

    try {
      // loging test case info
      await SRCWrapper.logTestcaseInfo(value, checkout.srcCorrelationId, null);

      let response = await window.DGNCheckout.checkout(checkout);
      console.log(response);

      if (this.state.blankWindowRef) {
        dcfWindow.close();
      }

      if (this.state.contentWindow) {
        var frame = document.getElementById("dcfIframe");
        if (frame != null) {
          frame.parentNode.removeChild(frame);
        }
      }

      if (response.dcfActionCode && response.dcfActionCode == "COMPLETE") {
        store.dispatch(loaderState(false));

        this.setState({
          errorResponse: true,
          transactionStatus: true,
          dcfActionCode: response.dcfActionCode
        });

      } else {
        store.dispatch(loaderState(false));
        this.setState({ errorResponse: false, errorStatus: true, errorMessage: response.error.errorDescription });
      }



    } catch (error) {
      this.setState({
        errorStatus: true,
        errorMessage: error.description
      });

      if (this.state.blankWindowRef) {
        dcfWindow.close();
      }

      if (this.state.contentWindow) {
        var frame = document.getElementById("dcfIframe");
        frame.parentNode.removeChild(frame);
      }

      store.dispatch(loaderState(false));
    }

  };

  componentDidMount() {
    store.dispatch(loaderState(false));
    testCaseName = SRCWrapper.testCaseInfo;
    if (testCaseName == "" || testCaseName == "SRC_07_31") {
      console.log("-----");
      this.setState({ contentWindow: false, noWindowRef: false, blankWindowRef: false, dcfInvokeStatus: false, emptyWindowRef: true });
    }

  }



  render() {
    return (
      <div id="paymentCardPage">

        {(this.state.errorResponse) ? <Redirect to='/thank-you' /> : null}

        <Container fluid={true} className="checkout-page-container">
          <PaymentNavBarComponent />

          <h2 className="text-center pt-3 paymentcard-page-title">We secure your information<br /></h2>
          <p className="text-center paymentcard-page-subtitle colorOrange" >Together We Work</p>

          <div className="row text-center card-details-container">
            <p className="col-6 mt-2 mb-2 card-details-title">New User</p>
            <p className="col-6 mt-2 mb-2 card-details-title returningUser disabled">Returning User</p>
          </div>
          <div className="card-details-container-inner">
            <div className="row card-details">
              <div className="col-12">
                <p className="px-2 pt-3 pb-1 card-details-title">Enter your card details</p>
              </div>
            </div>

            <form className="row card-details-form" id="frmCardDetails">
              <div className="form-group w-100 px-4 card-details-form">


                {/* div input for screen-shot caputring with added data  */}
                <div className="single-line inputBox py-4 form-control" contenteditable="true" data-max-length="20"
                  name="cardNumberInput"
                  id="cardNumberInput" aria-describedby="cardNumberHelp" placeholder="Card Number"
                  autoComplete='off'
                  value={this.state.cardNumberInput}
                >
                </div>

                {this.state.validationErrorCardNo ? (
                  <small className='validation-msg' >Enter valid card number.</small>
                ) : null}
              </div>

              <div className="row px-4 col-md-12">
                <div className="form-group  card-details-form col-6">
                  {/* div input for screen-shot caputring with added data  */}
                  <div className="single-line smallBox form-control py-4" contenteditable="true" data-max-length="10"
                    value={this.state.cardExpiryInput}
                    name="cardExpiryInput" id="cardExpiryInput" aria-describedby="cardExpiryrHelp"
                    placeholder="MM/YYYY" >
                  </div>

                  {this.state.validationErrorExpiryDate ? (
                    <small className='validation-msg'>Enter valid expiry month and year(MM/YYYY)</small>
                  ) : null}
                </div>

                <div className="form-group card-details-form col-6">

                  {/* div input for screen-shot caputring with added data  */}
                  <div className="single-line smallBox form-control py-4" contenteditable="true" data-max-length="10"
                    value={this.state.cardSCodeInput}
                    name="cardSecurityCodeInput" id="cardSCodeInput"
                    aria-describedby="cardSCodeHelp" placeholder="Security Code"
                  >
                  </div>
                  {this.state.validationErrorCvvNo ? (
                    <small className='validation-msg'>Enter valid security no</small>
                  ) : null}

                </div>
                {this.state.errorStatus ? (
                  <small className='validation-msg'>{this.state.errorMessage}</small>
                ) : null}

              </div>
            </form>

            <Label className="card-details-title marginLeft15px marginTop12px">INVOKE DCF AS:</Label>


            <Col xs={12} class="marginLeft15px" style={{ textAlign: "left", margin: 5 }}>
              <Label check>
                <Input
                  type="radio"
                  name="dcfInvoke"
                  value="contentWindow"
                  onChange={this.selectDcfInvoke}
                  checked={this.state.contentWindow === true}
                />{" "}
                Content WindowRef
                </Label>
            </Col>

            <Col xs={12} class="marginLeft15px" style={{ textAlign: "centre", margin: 5 }}>
              <Label check>
                <Input
                  className="px-2 pt-3 pb-1 "
                  type="radio"
                  name="dcfInvoke"
                  value="noWindowRef"
                  onChange={this.selectDcfInvoke}
                  checked={this.state.noWindowRef === true}
                />{" "}
                WindowRefLess
                </Label>
            </Col>

            <Col xs={12} marginLeft15px style={{ textAlign: "left", margin: 5 }}>
              <Label check>
                <Input
                  type="radio"
                  name="dcfInvoke"
                  value="blank"
                  onChange={this.selectDcfInvoke}
                  checked={this.state.blankWindowRef === true}
                />{" "}
                Blank WindowRef
                </Label>
            </Col>

            {(testCaseName == "SRC_07_28" || testCaseName == "SRC_07_31") ?
              <Col xs={12} marginLeft15px style={{ textAlign: "left", margin: 5 }}>
                <Label check>
                  <Input
                    type="radio"
                    name="dcfInvoke"
                    value="emptyWindowRef"
                    onChange={this.selectDcfInvoke}
                    onLoad={this.changeEmptyWindowRef}
                    checked={this.state.emptyWindowRef === true}
                  />{" "}
                  Invalid WindowRef
                </Label>
              </Col> : null
            }

            <Col xs={12} style={{ textAlign: "left" }}>
              {this.state.dcfInvokeStatus ? (
                <small className="validation-msg">
                  {this.state.dcfInvokeMsg}
                </small>
              ) : null}
            </Col>

            <div className="row btn-continue-row pb-3">
              <div className="col-md-12 col-sm-12 align-self-center">
                <div className="checkout-button text-center px-4 col-md-5">
                  <button type="button" onClick={this.enrollCardClick} id="btnPaymentCard"
                    className="btn btn-continue btn-lg btn-block mt-2 width111px">Continue</button>
                </div>

                <div className="checkout-button text-center px-4 col-md-8 btnDirectCheckout">
                  <button type="button" onClick={this.directCheckout} id="btnDirectCheckout"
                    className="btn btn-continue btn-lg btn-block mt-2">Direct Checkout</button>
                </div>

              </div>
            </div>
          </div>

          <FooterNavBarComponent />
        </Container>
      </div>

    );
  }
}