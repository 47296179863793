import * as React from 'react';
import { loggerHost } from "../logger/loggerConstants";

export class LoginNavBarComponent extends React.Component<any, any> {
  render() {
    return (
        <nav className="navbar navbar-expand-md navbar-light bg-light checkout-navbar paymentcard-navbar ">
        <div className="navbar ">
            <ul className="navbar-nav mr-auto">
                <img src={loggerHost.referenceAppuri + "SRC_Icon.png"} width="50" height="50" className="srcLogo margingLeft27px" />
                <div className="vl"></div>
                <img src={loggerHost.referenceAppuri + "discover-logo.png"} className="discoverLogo marginRight10px" width="100" height="50" />
            </ul>
        </div>
        <div className="mx-auto order-0">
        </div>
    </nav>);
  }
}
