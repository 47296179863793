import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import { PaymentNavBarComponent } from '../../components/PaymentNavBar';
import { store } from "../../redux/store";
import { Redirect } from 'react-router-dom';
import { Col, Row } from "reactstrap";
import { loaderState, srcCorrelationId, cardList, idTokens } from "../../redux/actions/Action";
import { connect } from "react-redux";
import SRCWrapper from "../../utility/SRCWrapper";
import html2canvas from "html2canvas";
import { logImage } from "../../utility/utility.js";
import DataParamsController from "../../Controllers/DataParamsController";
import { Input, Label } from "reactstrap";


export class ScenarioProfilesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorStatus: false,
            errorMessage: "",
            errorDescription: "",
            cardList: null,
            checkoutResponse: null,
            completeTransaction: false,
            noWindowRef: false,
            blankWindowRef: false,
            contentWindow: true,
            dcfInvokeStatus: false,
            dcfInvokeMsg: "",
            addCard: false
        };
        this.cardSelected = this.cardSelected.bind(this);
    }
    async componentDidMount() {
        store.dispatch(loaderState(true));

        var srcProfiles = await DataParamsController.getParamsForTheAPICall("getSrcProfile");
        srcProfiles.idTokens = store.getState().reducer.idTokens;

        try {
            let response = await window.DGNCheckout.getSrcProfile(srcProfiles);

            if (response.srcCorrelationId) {
                store.dispatch(srcCorrelationId(response.srcCorrelationId));
                this.setState({ cardList: response.profiles[0].maskedCards });
                store.dispatch(cardList(response.profiles[0].maskedCards));

            } else {
                store.dispatch(loaderState(false));
                this.setState({
                    errorStatus: true,
                    errorMessage: response.error.reason,
                    errorDescription: response.error.message
                });
            }
            store.dispatch(loaderState(false));
        } catch (error) {
            store.dispatch(loaderState(false));
            this.setState({
                errorStatus: true,
                errorMessage: error.reason,
                errorDescription: error.description
            });
        }
    }

    selectDcfInvoke = dcfInvokeType => {
        let type = dcfInvokeType.target.value

        switch (type) {
            case "noWindowRef":
                this.setState({ noWindowRef: true, blankWindowRef: false, contentWindow: false, dcfInvokeStatus: false });
                break

            case "blank":
                this.setState({ blankWindowRef: true, noWindowRef: false, contentWindow: false, dcfInvokeStatus: false });
                break

            case "contentWindow":
                this.setState({ contentWindow: true, noWindowRef: false, blankWindowRef: false, dcfInvokeStatus: false });
                break

            default:
                break;
        }
    };

    cardSelected = async cardId => {
        let dcfWindow;

        try {
            var maskedCards = store.getState().reducer.cardList;

            if (maskedCards != null && maskedCards.length > 0) {
                maskedCards.forEach(function (item, index) {
                    if (item.srcDigitalCardId != null && item.srcDigitalCardId == cardId) {
                        store.dispatch(cardList(item));
                    }
                });
            }

            store.dispatch(loaderState(true));

            var checkout = await DataParamsController.getParamsForTheAPICall("checkout");

            const { location: { state: { response: { dcfActionCode } } } } =
                this.props.location && this.props.location.state &&
                    this.props.location.state.response ?
                    this.props :
                    { location: { state: { response: { dcfActionCode: null } } } };


            console.log(this.props.srcCorrelationId);

            checkout.srciTransactionId = SRCWrapper.srcTransactionID;
            checkout.srcCorrelationId = store.getState().reducer.srcCorrelationId;
            checkout.srcDigitalCardId = cardId;

            let value = {};

            if (this.state.contentWindow === true) {
                let dcfIframe = document.createElement('iframe');
                dcfIframe.setAttribute('id', 'dcfIframe');
                dcfIframe.setAttribute('name', 'DCFIframe');
                dcfIframe.setAttribute('src', '');
                dcfIframe.style.zIndex = '2';
                dcfIframe.style.position = "absolute";
                dcfIframe.style.display = 'block';
                dcfIframe.style.width = '30%';
                dcfIframe.style.height = '92vh';
                dcfIframe.style.margin = '2% 35%';
                document.body.after(dcfIframe, document.body);
                checkout.windowRef = document.getElementById('dcfIframe').contentWindow;
            }

            if (this.state.blankWindowRef === true) {
                dcfWindow = window.open('about:blank');
                checkout.windowRef = dcfWindow;
            }

            console.log(checkout);

            let testCaseInfo = await SRCWrapper.logTestcaseInfo(value, store.getState().reducer.srcCorrelationId, null);

            console.log(testCaseInfo);
            let response = await window.DGNCheckout.checkout(checkout);
            console.log(response);

            if (this.state.blankWindowRef) {
                dcfWindow.close();
            }

            if (this.state.contentWindow) {
                var frame = document.getElementById("dcfIframe");

                if (frame != null) {
                    frame.parentNode.removeChild(frame);
                }
            }

            switch (response.dcfActionCode) {
                case "COMPLETE":
                    this.setState({ completeTransaction: true });
                    break;

                case "ADD_CARD":
                    this.setState({ enrollCard: true, addCard: true });
                    break;

                case "SWITCH_CONSUMER":
                    this.setState({ switchConsumer: true });
                    break;

                case "CHANGE_CARD":
                    await this.componentDidMount();
                    break;

                default: // CANCEL || ERROR
                    if (response.dcfActionCode == "ERROR") {
                        this.setState({
                            errorStatus: true,
                            errorMessage: response.error.errorCode,
                            errorDescription: response.error.errorDescription
                        });
                    }
            }
            store.dispatch(loaderState(false));
        } catch (error) {
            console.log(error);
            if (this.state.contentWindow) {
                var frame = document.getElementById("dcfIframe");
                console.log(frame);
                if (frame != null) {
                    frame.parentNode.removeChild(frame);
                }
            }

            if (this.state.blankWindowRef) {
                dcfWindow.close();
            }

            store.dispatch(loaderState(false));
            this.setState({
                errorStatus: true,
                errorMessage: error.reason,
                errorDescription: error.description
            });
        }
    };

    enrollCardClick = () => {
        this.setState({ addCard: true });
    };

    render() {
        return (
            <div id="profilePage">
                {
                    this.state.completeTransaction === true ? (<
                        Redirect to="/thank-you"
                    />
                    ) : null
                }

                {
                    this.state.addCard === true ? (
                        <Redirect to='/addCard?scenario_4' />
                    ) : null
                }

                <Container fluid={true} className="checkout-page-container">
                    <PaymentNavBarComponent />

                    <div id='src-trigger'
                        className='parent-card'>


                        <div className='page-card-content '>
                            <p className='title-card' > CARDS </p>
                            <hr className='heading-divider' />


                            <br />

                            {
                                this.state.cardList != null ? (
                                    <React.Fragment > {
                                        this.state.cardList.map((card, i) => (
                                            <Row className="profile-list-page" key={
                                                card.srcDigitalCardId
                                            }
                                                onClick={
                                                    () =>
                                                        this.cardSelected(card.srcDigitalCardId)
                                                }
                                            >
                                                <Col xs={
                                                    4
                                                }
                                                >
                                                    <img className="cardIcon" src={
                                                        card.digitalCardData.artUri
                                                    }
                                                        alt='Card Art'
                                                        style={
                                                            {
                                                                cursor: "pointer"
                                                            }
                                                        }
                                                    />
                                                </Col> <
                                                    Col xs={
                                                        8
                                                    }
                                                    style={
                                                        {
                                                            textAlign: "left"
                                                        }
                                                    } >
                                                    <p className='info-text' id={`card-${i + 1}`}
                                                        style={
                                                            {
                                                                cursor: "pointer"
                                                            }
                                                        } > {
                                                            card.digitalCardData
                                                                .presentationName
                                                        }
                                                        <br />
                                                        <small className='muted-text' >
                                                            XXXX - XXXX - XXXX - {
                                                                card.panLastFour
                                                            } </small> </p>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                    </React.Fragment>
                                ) : <p>No cards available.</p>
                            }


                            <button type="button" onClick={this.enrollCardClick} id="btnPaymentCard"
                                className="btn btn-checkout width50Percent btn-lg btn-block mt-2">Add Card</button>


                            <Label className="card-details-title marginLeft15px marginTop12px">INVOKE DCF AS:</Label>


                            <Col xs={12} class="marginLeft15px" style={{ textAlign: "left", margin: 5 }}>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="dcfInvoke"
                                        value="contentWindow"
                                        onChange={this.selectDcfInvoke}
                                        checked={this.state.contentWindow === true}
                                    />{" "}
                                    Content WindowRef
                                    </Label>
                            </Col>

                            <Col xs={12} class="marginLeft15px" style={{ textAlign: "centre", margin: 5 }}>
                                <Label check>
                                    <Input
                                        className="px-2 pt-3 pb-1 "
                                        type="radio"
                                        name="dcfInvoke"
                                        value="noWindowRef"
                                        onChange={this.selectDcfInvoke}
                                        checked={this.state.noWindowRef === true}
                                    />{" "}
                                    WindowRefLess
                                </Label>
                            </Col>

                            <Col xs={12} marginLeft15px style={{ textAlign: "left", margin: 5 }}>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="dcfInvoke"
                                        value="blank"
                                        onChange={this.selectDcfInvoke}
                                        checked={this.state.blankWindowRef === true}
                                    />{" "}
                                    Blank WindowRef
                                 </Label>
                            </Col>

                            <Col xs={12} style={{ textAlign: "left" }}>
                                {this.state.dcfInvokeStatus ? (
                                    <small className="validation-msg">
                                        {this.state.dcfInvokeMsg}
                                    </small>
                                ) : null}
                            </Col>
                            {
                                this.state.errorStatus ? (<small className='validation-msg'> {
                                    this.state.errorMessage
                                } <br /> {
                                        this.state.errorDescription
                                    } </small>
                                ) : null
                            } </div>
                    </div>
                    <FooterNavBarComponent />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loader: state.reducer.loaderState,
        idTokens: state.reducer.idTokens,
        srcCorrelationId: state.reducer.srcCorrelationId,
        srciTransactionId: state.reducer.srciTransactionId
    };
};

export default connect(mapStateToProps)(ScenarioProfilesPage);