import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import { FooterNavBarComponent } from '../../components/FooterNavBar';
import { LoginNavBarComponent } from '../../components/LoginNavBar';
import SignInController from "../../Controllers/SignInController";
import Amplify, { Auth } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import { store } from "../../redux/store";
import { loaderState } from "../../redux/actions/Action";

export class IRA_NewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUserSignedIn: false,
            errorStatus: false,
            errorMessage: "",
            authenticationSuccess: false,
            newPassword: "",
            confirmPassword: "",
            isUserSignedIn: false
        };

        this.newPassword = this.newPassword.bind(this);
        this.confirmPassword = this.confirmPassword.bind(this);
    }

    newPassword = e => {
        this.setState({
            newPassword: e.target.value
        });
    };

    confirmPassword = e => {
        this.setState({
            confirmPassword: e.target.value
        });
    };

    changePassword = async (event) => {
        event.preventDefault();
        store.dispatch(loaderState(true));

        var newPassword = this.state.newPassword.trim();
        var confirmPassword = this.state.confirmPassword.trim();


        if (newPassword != confirmPassword) {
            this.setState({ errorStatus: true, errorMessage: "New password and confirm password should match." });
            store.dispatch(loaderState(false));
            return;
        }

        await SignInController.changePassword(newPassword);

        if (Amplify.Auth.user != null) {
            this.setState({ isUserSignedIn: true });
        } else {
            this.setState({ errorStatus: true, errorMessage: "Check your password and network connectivity" });
        }

        store.dispatch(loaderState(false));
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(user => this.setState({ isUserSignedIn: true }))
            .catch(err => this.setState({ isUserSignedIn: false }));

    };

    render() {
        return (
            <div id="loginPage">

                {this.state.isUserSignedIn ? <Redirect to='/referenceApp/checkout' /> : null}

                <Container fluid={true} className="checkout-page-container">
                    <LoginNavBarComponent />
                    <div className="box">
                        <h3 className="text-center pt-3 pb-0 login-page-title">Change password</h3>
                        <hr className="breakLine"></hr>

                        <form className="col-12 marginTop25px">

                            <div>
                                <input type="password" id="password" name="password" placeholder="New Password"
                                    value={this.state.newPassword}
                                    onChange={this.newPassword}
                                ></input>
                                {/* <label for="email">Email address:</label> */}
                                <input type="password" id="password" name="confirmPassword" placeholder="Confirm Password"
                                    value={this.state.confirmPassword}
                                    onChange={this.confirmPassword}>

                                </input>


                                <p>Password Policies</p>
                                <ul class="passwordPolicy">
                                    <li>Password must contain a number</li>
                                    <li>Password must contain at least 8 characters</li>

                                    <li>Password must contain a lower case letter</li>
                                    <li>Password must contain an upper case letter</li>
                                    <li>Password must contain a special character</li>
                                </ul>

                            </div>

                            {
                                this.state.errorStatus ? (
                                    <small className='validation-msg' >{this.state.errorMessage}</small>
                                ) : null
                            }

                            <button type="submit" onClick={this.changePassword} className="text-center btn btn-login btn-block mt-2">Go</button>


                        </form>
                    </div>
                    <FooterNavBarComponent />
                </Container>
            </div>);
    }
}