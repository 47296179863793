import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import Routes from "./Routes";
import "./App.css";
import Loader from "./components/loading-spinner/Loader";

class App extends Component {
	render() {
		return (
			<div id='main-app'>
				{this.props.loader ? <Loader /> : null}
				<BrowserRouter>
					<Routes />
				</BrowserRouter>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		loader: state.reducer.loaderState
	};
};

export default connect(mapStateToProps)(App);
