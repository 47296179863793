import React, { Component } from 'react';
import { slide as Menu } from "react-burger-menu";
import { Container, Row, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import { loggerHost } from "../logger/loggerConstants";
import SignInController from "../Controllers/SignInController";

export class SideBarComponent extends Component {
    render() {
        return (
            <div className="SidebarMenu">
                <Menu disableAutoFocus width={'250px'}>
                    <Row>
                        <Col className="userProfile" md={5}>
                            <img className="avatar" src={loggerHost.referenceAppuri + "person.jpg"} />
                            <span className="userName" > </span>
                        </Col>
                    </Row>

                    {/* <a className="menu-item" href="#">
                <FontAwesomeIcon icon={faUser} color="green" className="sideIcon" /> My Account</a> */}

                    <a className="menu-item" onClick={SignInController.signOut}>
                        <FontAwesomeIcon icon={faSignOutAlt} color="green" style={{ cursor: "pointer !important" }} className="sideIcon" /> Sign Out </a>
                </Menu>
            </div>
        )
    };
}