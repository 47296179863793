import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { FooterNavBarComponent } from "../../components/FooterNavBar";
import { LoginNavBarComponent } from "../../components/LoginNavBar";
import SignInController from "../../Controllers/SignInController";
import Amplify, { Auth } from "aws-amplify";
import { Redirect } from "react-router-dom";
import { store } from "../../redux/store";
import { loaderState } from "../../redux/actions/Action";
import { Link } from "react-router-dom";

export class IRA_Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserSignedIn: false,
      errorStatus: false,
      errorMessage: "",
      authenticationSuccess: false,
      email: "",
      password: "",
      changePassword: false,
    };

    this.emailIdentity = this.emailIdentity.bind(this);
    this.password = this.password.bind(this);
  }

  emailIdentity = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  password = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  doSignIn = async (event) => {
    event.preventDefault();
    store.dispatch(loaderState(true));

    var username = this.state.email.trim();
    var password = this.state.password.trim();

    var user = await SignInController.signIn(username, password);

    // Asking user to change the password after their first login 
    if (user.challengeName == "NEW_PASSWORD_REQUIRED") {
      this.setState({ changePassword: true });
    }

    // Validating sign in process
    if (Amplify.Auth.user != null) {
      this.setState({ authenticationSuccess: true });
    } else {
      this.setState({
        errorStatus: true,
        errorMessage: (user != null && user.message != null) ? user.message : "Incorrect username or password.",
      });
    }

    store.dispatch(loaderState(false));
  };

  componentDidMount() {
    store.dispatch(loaderState(false));

    Auth.currentAuthenticatedUser()
      .then((user) => this.setState({ isUserSignedIn: true }))
      .catch((err) => this.setState({ isUserSignedIn: false }));
  }

  render() {
    return (
      <div id="loginPage">
        {this.state.changePassword ? (
          <Redirect to="/referenceApp/new-password" />
        ) : null}

        {this.state.authenticationSuccess || this.state.isUserSignedIn ? (
          <Redirect to="/referenceApp/checkout" />
        ) : null}

        <Container fluid={true} className="checkout-page-container">
          <LoginNavBarComponent />
          <div className="box">
            <h3 className="text-center pt-3 pb-0 login-page-title">Sign In</h3>
            <hr className="breakLine"></hr>

            <form className="col-12 marginTop25px">
              <div>
                <input
                  type="email"
                  id="email"
                  placeholder="User Name"
                  value={this.state.email}
                  onChange={this.emailIdentity}
                ></input>
                {/* <label for="email">Email address:</label> */}
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.password}
                ></input>
                <Link
                  to="/referenceApp/forgot-password"
                  style={{
                    float: "right",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  Forgot Password?
                </Link>
              </div>

              {this.state.errorStatus ? (
                <small className="validation-msg">
                  {this.state.errorMessage}
                </small>
              ) : null}

              <button
                type="submit"
                onClick={this.doSignIn}
                className="text-center btn btn-login btn-block mt-2"
              >
                Go
              </button>
            </form>
          </div>
          <FooterNavBarComponent />
        </Container>
      </div>
    );
  }
}
